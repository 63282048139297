import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            textAlign: "left",
        },
        title: {
            flexGrow: 1,
            align: "left",
        },
        notificationButton: {
        },
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        bottomNavigation: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        profileIcon: {
            width: 25,
            height: 25,
        },
    }),
);