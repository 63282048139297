import Axios from "axios"
import { SERVER_URL } from "../constant/base.constant";
import { SecurityManager } from "../security/security.manager";

export const useHttpIntercepter = () => {

  Axios.defaults.baseURL = SERVER_URL

  Axios.interceptors.request.use(config => {
    // console.log(`request =====>${JSON.stringify(config)}`)
    const token = SecurityManager.getToken()
    if (token && token.length > 0) {
      const httpOptions = {
        'Authorization': token
      }
      Object.assign(config.headers, httpOptions)
    }
    return config
  })

  Axios.interceptors.response.use(response => {
    // console.log(`response =====>${JSON.stringify(response)}`)
    return response
  }, error => {
    // console.log(`error =====>${JSON.stringify(error)}`)
    if (error && error.response && error.response.status === 401) {
      // SecurityManager.logout()
    }
    return Promise.reject(error)
  })
}