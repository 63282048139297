import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            verticalAlign: 'middle',
            justifyContent: 'center',
        },
        imageHolder: {
            width: 203,
            height: 203,
            margin: 'auto',
            borderRadius: 150,
            backgroundColor: '#ccc',
        },
        image: {
            width: '100%',
            height: '100%',
        },
        description: {
            width: 244,
            height: 67,
            margin: 'auto',
            textAlign: 'center',
            fontSize: 16,
            
            fontWeight: 700,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: 14,
            justifyContent: 'space-between',
            padding: 10,
        },
        button: {
            width: '45%',
            height: 38,
            textTransform: "none",
            fontSize: 13,
            borderRadius: 30,
        }
    })
)