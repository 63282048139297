import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            
            fontSize: 24,
        },
        title: {
            flexGrow: 1,
            fontSize: 14,
            marginLeft: theme.spacing(1),
        },
        imgContainer: {
            margin: 'auto',
            marginTop: 24,
            
            width: 78,
            height: 60,
        },
        title1: {
            width: '90%',
            margin: 'auto',
            marginTop: 25,
            align: 'center',
            justify: 'center',
            maxLines: 2,
            fontSize: 12,
            
        },
        title2: {
            align: "left",
            marginTop: 24,
            marginLeft: 17,
            fontSize: 12,
        },
        
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            marginLeft: 16,
            width: '91%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            marginLeft: 16,
            marginTop: 8, 
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginTop: 14,
            marginLeft: 21,
        },
        iconText: {
            alignSelf: "center",
            marginLeft:20,
            height:13,
            width:40,
            fontSize: 10,
        },
        requestButton:{
            width: '95%',
            alignSelf: 'center',
            justifySelf: 'center',
            padding: 10,
            fontSize: 14,
            textTransform: 'capitalize',
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);