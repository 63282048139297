import { EditProfileAction } from "../action/edit-profile.action";
import { DelarService } from "../service/delar.service";
import { EditProfileStore } from "../store/edit-profile.store";

const updateData = (delarId: string) => {
    const delarDto = EditProfileStore.getDelarDto()
    if (delarDto) {
        DelarService.updateDelarById(delarId, delarDto)
    }
}

export const EditProfileActor = (action: any, data: any) => {
    switch (action.type) {
        case EditProfileAction.UPDATE_NAME:
            EditProfileStore.updateName(data.name)
            break;
        case EditProfileAction.UPDATE_PHONE:
            EditProfileStore.updatePhone(data.phone)
            break;
        case EditProfileAction.UPDATE_INTRO:
            EditProfileStore.updateIntro(data.intro)
            break;
        case EditProfileAction.UPDATE_SHOP_NAME:
            EditProfileStore.updateShopName(data.newShopName)
            break;
        case EditProfileAction.UPDATE_PESTICIDE_LICENSE:
            EditProfileStore.updatePesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case EditProfileAction.UPDATE_FERTILIZER_LICENSE:
            EditProfileStore.updateFertilizerLicenseNumber(data.fertilizerLicenseNumber)
            break;
        case EditProfileAction.UPDATE_SEED_LICENSE:
            EditProfileStore.updateSeedLicenseNumber(data.seedLicenseNumber)
            break;
        case EditProfileAction.UPDATE_STREET_ADDRESS:
            EditProfileStore.updateStreetAddress(data.streetLine)
            break;
        case EditProfileAction.UPDATE_LANDMARK:
            EditProfileStore.updateLandmark(data.landMark)
            break;
        case EditProfileAction.UPDATE_STATE:
            EditProfileStore.updateState(data.state)
            break;
        case EditProfileAction.UPDATE_DISTRICT:
            EditProfileStore.updateState(data.district)
            break;
        case EditProfileAction.UPDATE_SUB_DISTRICT:
            EditProfileStore.updateState(data.subDistrict)
            break;
        case EditProfileAction.UPDATE_VILLAGE:
            EditProfileStore.updateState(data.village)
            break;
        case EditProfileAction.UPDATE_PINCODE:
            EditProfileStore.updatePincode(data.pincode)
            break;
        case EditProfileAction.UPDATE_DATA:
            updateData(data.delarId)
            break;
        default:
            break;
    }
}