import { BehaviorSubject, Observable } from "rxjs"
import { ApiError } from "../model/error.model"
import { District } from "../service/response/district.response"
import { State } from "../service/response/state.response"
import { SubDistrict } from "../service/response/sub-district.response"
import { Village } from "../service/response/village.response"

let _stateList = new BehaviorSubject<Array<State> | ApiError>([])
let _districtList = new BehaviorSubject<Array<District> | ApiError>(new Array())
let _subDistrictList = new BehaviorSubject<Array<SubDistrict> | ApiError>(new Array())
let _villageList = new BehaviorSubject<Array<Village> | ApiError>(new Array())
let _selectedVillageList = new BehaviorSubject<Array<Village> | ApiError>(new Array())

export const AddressStore = {
    getStateList: (): Array<State> | ApiError => {
        return _stateList.value
    },
    setStateList: (newStateList: Array<State> | ApiError) => {
        _stateList.next({ ...newStateList })
    },
    getStateListObservable: (): Observable<Array<State> | ApiError> => {
        return _stateList.asObservable()
    },
    getDistrictList: (): Array<District> | ApiError => {
        return _districtList.value
    },
    setDistrictList: (newStateList: Array<District> | ApiError) => {
        _districtList.next({ ...newStateList })
    },
    getDistrictListObservable: (): Observable<Array<District> | ApiError> => {
        return _districtList.asObservable()
    },
    getSubDistrictList: (): Array<SubDistrict> | ApiError => {
        return _subDistrictList.value
    },
    setSubDistrictList: (newStateList: Array<SubDistrict> | ApiError) => {
        _subDistrictList.next({ ...newStateList })
    },
    getSubDistrictListObservable: (): Observable<Array<SubDistrict> | ApiError> => {
        return _subDistrictList.asObservable()
    },
    getVillageList: (): Array<Village> | ApiError => {
        return _villageList.value
    },
    setVillageList: (newVillageList: Array<Village> | ApiError) => {
        _villageList.next({ ...newVillageList })
    },
    getVillageListObservable: (): Observable<Array<Village> | ApiError> => {
        return _villageList.asObservable()
    },
    getSelectedVillageList: (): Array<Village> | ApiError => {
        return _selectedVillageList.value
    },
    getSelectedVillageListObservable: (): Observable<Array<Village> | ApiError> => {
        return _selectedVillageList.asObservable()
    },
    
}