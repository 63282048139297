import Axios, { AxiosResponse } from "axios";
import { DelarProductDto, DelarProductSearchDto, ProductSearchDto } from "./dto/product.dto";
import { DelarProduct, DelarProductRes, DelarProductSearchResultRes, Product } from "./response/product.response";

export const ProductService = {
    getDelarProductList: (delarProductSearchDto: DelarProductSearchDto): Promise<AxiosResponse<DelarProductRes>> => {
        return Axios.post<DelarProductRes>(`/delar-product-search`, delarProductSearchDto)
    },
    getProductList: (productSearchDto: ProductSearchDto): Promise<AxiosResponse<DelarProductSearchResultRes>> => {
        return Axios.post<DelarProductSearchResultRes>(`/product-search`, productSearchDto)
    },
    addDelarProductList: (delarProductDtoList: Array<DelarProductDto>): Promise<AxiosResponse<Array<DelarProduct>>> => {
        return Axios.post<Array<DelarProduct>>(`/delar-product-list`, delarProductDtoList)
    },
    getDelarProductById: (delarProductId: string): Promise<AxiosResponse<DelarProduct>> => {
        return Axios.get<DelarProduct>(`/delar-product/${delarProductId}`)
    },
}