import Axios, { AxiosResponse } from "axios";


import { Company } from "./response/company.response";
import { Category } from "./response/category.response";
export const FilterService = {
    getCompany: (): Promise<AxiosResponse<Array<Company>>> => {
        return Axios.get<Array<Company>>(`/company`)
    },
    getCropCategory: (): Promise<AxiosResponse<Array<Category>>> => {
        return Axios.get<Array<Category>>(`/crop-category`)
    },
}