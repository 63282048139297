import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#fff',
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.spacing(3),
              width: 'auto',
            },
          },
          searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#000',
            opacity: 0.5,
          },
        inputRoot: {
            color: '#000',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '20ch',
            },
        },
        topMenu: {
            display:"flex", 
            width:"100%",
            height:56, 
            borderBottom: '1px solid #ccc',
        },
        buttonStyle: {
            textTransform: 'capitalize',
            width:'50%',
            marginLeft:0,
            marginTop:0,
            fontSize: 14,
            
        },
        separator: {
            width:1,
            height: 56,
            background: "#000",
        },
        topIcon: {
            height:15,
            width:15,
            marginRight:13,
        },
        productName: {
            fontSize: 12,
            fontWeight: 600,
        },
        productDescription: {
            fontSize: 11,
            opacity: 0.8,
        },
        nextButton: {
            position: 'fixed',
            right:16,
            width: 170.5,
            height: 48,
            fontWeight: 500,
            fontSize: 13,
        },
        floatingIcon: {
            position: 'fixed',
            right:16,
            bottom:70,
            width: 170.5,
            height: 48,
            fontWeight: 500,
            fontSize: 13,
            textTransform: 'capitalize',
        },
        paper: {
            zIndex: 1,
            position: 'relative',
            margin: theme.spacing(1),
          },
          svg: {
            width: 100,
            height: 100,
          },
          polygon: {
            fill: theme.palette.common.white,
            stroke: theme.palette.divider,
            strokeWidth: 1,
          },
          container: {
            position: "fixed",
            visibility: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            zIndex: 21,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            overflow: "hidden"
          },
          backgroundContainer: {
            position: "absolute",
            zIndex: 1,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,.5)",
            fontWeight: 'bold',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          },
          list: {
            position: "relative",
            zIndex: 2,
            listStyle: "none",
            margin: 0,
            
            backgroundColor: "#fff",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            padding: '8px 0',
            transform: "translateY(0%)"
          },
          button: {
            backgroundColor: "#fff",
            border: 0,
            margin: 0,
            width: "100%",
            display: "flex",
            flex: "0 1 auto",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            textTransform: "none",
            lineHeight: "48px",
            padding: "0 16px",
            borderRadius: 0,
            outline: "none",
            cursor: "pointer"
          },
          text: {
            fontSize: "16px"
          },
        
    }),
);