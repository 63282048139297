import { DelarAction } from "../action/delar.action";
import { Media } from "../model/media.model";
import { DelarService } from "../service/delar.service";
import { StorageService } from "../service/storage.service";
import { DelarStore } from "../store/delar.store";

const saveData = (delarId: string, action: any, errorAction: any) => {
    const delarDto = DelarStore.getDelarDto()
    if (delarDto) {
        DelarService.updateDelarById(delarId, delarDto).then(res => {
            action()
        }).catch(err => {
            errorAction()
        })
    }
}

const openDelarInfo = (delarID: string) => {
    DelarService.getDelarById(delarID).then((res) => {
        DelarStore.upadteDelarInfo(res.data)
    }).catch(err => {
        console.log(err)
    })
}

const shopImagesUpload = (delarId: string, shopPhotos: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    shopPhotos.map(shopPhoto => {
        promises.push(StorageService.uploadShopImage(shopPhoto.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateShopImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const pesticideImagesUpload = (delarId: string, pesticideImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    pesticideImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updatePesticideImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const fertiliserImagesUpload = (delarId: string, fertiliserImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    fertiliserImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateFertiliserImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const seedImagesUpload = (delarId: string, seedImages: any[], onUploaded: any, action: any, errorAction: any) => {
    const promises: any[] = []
    seedImages.map(image => {
        promises.push(StorageService.uploadDocument(image.file))
    })

    Promise.all(promises).then((medias: Array<Media>) => {
        onUploaded()
        DelarStore.updateSeedImages(medias)
        saveData(delarId, action, errorAction)
    }).catch(err => {
        errorAction()
    });
}

const reInitDelarDto = (delarId: string, action: any, errorAction: any) => {
    DelarService.getDelarById(delarId).then(res => {
        DelarStore.reInitDelarDto(res.data)
        action()
    }).catch(err => {
        errorAction()
    })
}

export const DelarActor = (action: any, data: any): any => {
    switch (action.type) {
        case DelarAction.UPDATE_NAME:
            DelarStore.updateName(data.name)
            break;
        case DelarAction.UPDATE_ALTERNATE_NUMBER:
            DelarStore.updateAlternatePhone(data.alternatePhone)
            break;
        case DelarAction.UPDATE_INTRO:
            DelarStore.updateIntro(data.intro)
            break;
        case DelarAction.UPDATE_SHOP_NAME:
            DelarStore.updateShopName(data.shopName)
            break;
        case DelarAction.UPDATE_STREET_ADDRESS:
            DelarStore.updateStreetAddress(data.streetLine)
            break;
        case DelarAction.UPDATE_LANDMARK:
            DelarStore.updateLandmark(data.landMark)
            break;
        case DelarAction.UPDATE_STATE:
            DelarStore.updateState(data.state)
            break;
        case DelarAction.UPDATE_DISTRICT:
            DelarStore.updateDistrict(data.district)
            break;
        case DelarAction.UPDATE_SUB_DISTRICT:
            DelarStore.updateSubDistrict(data.subDistrict)
            break;
        case DelarAction.UPDATE_VILLAGE:
            DelarStore.updateVillage(data.village)
            break;
        case DelarAction.UPDATE_PIN_CODE:
            DelarStore.updatePinCode(data.pinCode)
            break;
        case DelarAction.UPDATE_PESTICIDE_LICENCE:
            DelarStore.updatePesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case DelarAction.UPDATE_FERTILIZER_LICENCE:
            DelarStore.updatePesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case DelarAction.UPDATE_SEED_LICENCE:
            DelarStore.updatePesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case DelarAction.UPDATE_SEED_LICENCE:
            DelarStore.updatePesticideLicenseNumber(data.pesticideLicenseNumber)
            break;
        case DelarAction.UPDATE_STATUS:
            DelarStore.updateStatus(data.value, data.msg)
            break;
        case DelarAction.RE_INIT_DELAR:
            reInitDelarDto(data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.SAVE_DATA:
            saveData(data.delarId, data.action, data.errorAction)
            break;
        case DelarAction.GET_DELAR_INFO:
            openDelarInfo(data.delarID)
            break;
        case DelarAction.UPLOAD_SHOP_IMAGES:
            shopImagesUpload(data.delarId, data.shopPhotos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_PESTICIDE_IMAGES:
            pesticideImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_FERTILISER_IMAGES:
            fertiliserImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.UPLOAD_SEED_IMAGES:
            seedImagesUpload(data.delarId, data.photos, data.onUploaded, data.action, data.errorAction);
            break;
        case DelarAction.VALIDATE_PERSONAL_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorPersonalDetailPage()
            break;
        case DelarAction.VALIDATE_BUSSINESS_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorBussinessDetailPage()
            break;
        case DelarAction.VALIDATE_DOCUMENT_PROOF_DETAIL_PAGE_ERROR:
            DelarStore.checkErrorDocumentProoofPage()
            break;
        default:
            break;
    }
}