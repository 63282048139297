import { AppBar, Avatar, BottomNavigation, Button, Card, Checkbox, Chip, Divider, InputBase, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './not-deliverable.style'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { Village } from "../../../../service/response/village.response"
import { useNavigate } from "react-router"
import CloseIcon from '@material-ui/icons/Close';
import LoadingComponent from "../../../common/loading/loading.component"
import { useTranslation } from "react-i18next"
import { SecurityManager } from "../../../../security/security.manager"
import { DelarService } from "../../../../service/delar.service"
import { AddressService } from "../../../../service/address.service"
import { VillageSearchDto } from '../../../../service/dto/village-search.dto'
import { useRouteManager } from "../../../../router/route.manager"
import { AddVillageDto } from "../../../../service/dto/add-village.dto"

export const NotDeliverable = () => {
    const classes = useStyles();
    return(
        
            <div className = {classes.root}>
                <div>
                <div className = {classes.imageHolder}>
                    <img className = {classes.image} src = "../asset/rejected.svg" />
                </div>
                <div className = {classes.description}>
                    <p>We do not serve at your location at this moment. We will soon serve at your location.</p>
                </div>
                <div className = {classes.buttonContainer}>
                    <Button variant = "outlined" className = {classes.button}>
                        Request Center
                    </Button>
                    <Button variant = "contained" color = "primary" className = {classes.button}>
                        Update Location
                    </Button>
                </div>
                </div>
            </div>
        
    )
}