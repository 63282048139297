import {TextField, Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { AddressAction } from '../../../action/address.action';
import { AddressActor } from '../../../actor/address.actor';
import { DelarStore } from "../../../store/delar.store"
import { State } from '../../../service/response/state.response';
import { AddressStore } from '../../../store/address.store';
import { useObservable } from '../../../useSubscriber';
import {useStyles} from './ui.style';
import { useTranslation } from "react-i18next"

export const StatePickerComponent = (props: any) => {

    const [stateList, stateListError] = useObservable(AddressStore.getStateList(), AddressStore.getStateListObservable())
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())
    const [stateData, setStateData] = useState<Array<State>>([])
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        AddressActor({ "type": AddressAction.GET_STATE_LIST }, {})
    }, [])

    useEffect(() => {
        if (!stateListError.error && stateList) {
            var newStates: Array<State> = Object.keys(stateList).map(function (index: any) {
                let state = (stateList as State[])[index]
                return state;
            });
            setStateData(newStates)
        }
    }, [stateList])

    return (
        <Autocomplete
            id="combo-box-demo"
            options={stateData}
            includeInputInList
            getOptionLabel={(option: State) => {
                return option.name
            }
            }
            onChange={(event, state) => props.setState(state)}
            fullWidth={true}
            loading={stateData.length > 0 ? true : false}
            style={{ marginTop: 8, fontSize: 12, }}
            classes={{ input: classes.input  }} 
            renderInput={(params: any) => <TextField {...params} 
            classes = {{
                root: delarDtoError?.state.error?classes.TextFieldRoot: classes.noStyle,
            }}
                label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("choose_state")}</Typography>}  variant="outlined" />}
        />
    );
}