import { createStyles, makeStyles, Theme} from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            fontSize: 12,
        },
        TextFieldRoot: {
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'red',
                },
            },
        },
        noStyle: {},
    })
);