import { AppBar, Avatar, BottomNavigation, Button, Container, Divider, TextField, Toolbar, Typography,} from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-shop.style'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useObservable } from "../../../../../useSubscriber"
import { EditProfileStore } from "../../../../../store/edit-profile.store"
import { EditProfileActor } from "../../../../../actor/edit-profile.actor"
import { EditProfileAction } from "../../../../../action/edit-profile.action"
import { GalleryPickerComponent } from "../../../../Image_Picker/gallery-picker.component"
import { SecurityManager } from "../../../../../security/security.manager"
import { useTranslation } from "react-i18next"

export const EditShopComponent = () => {

    const classes = useStyles();
    const [error, setError] = useState(true);
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const { t } = useTranslation();
    const handleChangeShopName = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_SHOP_NAME}, {"newShopName": e.target.value})
    };

    const handleUpdate = () => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid() })
    };
    
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("change_shop_name")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className = {classes.imgContainer}>
                <img alt={'editLicenseIllustration'} src={'/asset/Edit Shop Illustration.svg'} />
            </div>
            
            <Typography variant="subtitle1" className={classes.title1} align={'center'}>
                {t("change_requirement_shop_name")}
            </Typography>
            <Typography variant="body1" className={ classes.title2 } >
                <b>{t("shop_name")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.shopName?.newName} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your Shop Name</Typography>} variant="outlined" onChange={handleChangeShopName}  />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                    </Typography> }
            </form> 
            <Typography variant="body1" className={ classes.title2 } >
                <b>{t("upload_license_image")}</b>
            </Typography>
            <GalleryPickerComponent/>
            <BottomNavigation
                showLabels
                className={classes.bottomNavigation}
            >
                <Button variant="contained" color="primary" className={classes.requestButton} onClick={handleUpdate}>
                    {t("send_request")}
                </Button>
            </BottomNavigation>
        </div>
    )
}