import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom: 90,
        },
        title1: {
            align: "left",
            letterSpacing: 0,
            marginLeft: 0,
            display: "flex",
            fontSize: 24,
            fontWeight: 900,
        },
        title2: {
            align: "left",
            marginTop: 6,
            fontSize: 12,
        },
        camBox: {
            width: 79,
            height: 79,
        },
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            
            marginTop: 8, 
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginTop: 14,
            marginLeft: 21,
        },
        iconText: {
            alignSelf: "center",
            marginLeft:20,
            height:13,
            width:40,
            fontSize: 10,
        },
        title3: {
            align: "left",
            marginTop: 12,
        },
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            width: '98%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        title4: {
            align: "left",
            marginTop: 28,
            fontSize: 12,
        },
        searchIcon: {
            padding: 10,
        },
        TextFieldRoot: {
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'red',
                },
            },
        },
        noStyle: {},
    }),
);