import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        backIcon: {
            color: '#fff',
            
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        img: {
            position: 'relative',
            height: 360,
            display: 'block',
            overflow: 'hidden',
            width: '100%',
            zIndex: 0.8,
        },
        iconHolder: {
           position: 'absolute',
           width: 40,
           height: 40,
           borderRadius: 25, 
           boxShadow: '0px 1px 4px #00000029',
           right: 16,
           top: 16,
           zIndex: 1,
           background: '#FFF',
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center',
        },
        graphIcon: {
            height: 20,
            width: 20,
            marginLeft: 16,
            marginTop: 16,
        },
        title1: {
            marginLeft: 3,
            marginTop: 15,
            fontSize: 12,
        },
        title2: {
            marginLeft: 15,
            fontSize: 14,
            fontWeight: 600,
        },
        title3: {
            marginLeft: 15,
            fontSize: 12,
            opacity: 0.8,
            marginBottom: 12,
        },
        title4: {
            marginTop: 4,
            marginLeft: 15,
            maxLines: 10,
            fontSize: 12,
            opacity: 0.8,
        }
    }),
);