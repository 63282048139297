import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            textAlign: "left",
        },
        img: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            height: 200,
            maxWidth: "100%",
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        title1: {
            marginBottom: 0,
            marginLeft: 0,
            color: '#fff',
            position:'absolute',
            bottom:8, 
            left:15,
            fontSize: 14,
            fontWeight: 600,
        },
        title2: {
            marginTop: 12,
            marginLeft: 17,
            fontSize: 16,
            fontWeight: 600,
        },
        editIcon: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            marginRight: 17,
            marginTop: 12,
            width: 18,
            height: 18,
        },
        icon: {
            
            marginLeft: 16,
            marginTop: 11,
            width: 16,
            height: 16,
            
            
        },
        title3: {
            
            marginTop: 9,
            marginLeft: 11,
            marginRight: 'auto',
            fontSize: 12,
            lineSpacing: 0.24,
            
            
        },
    }),
);