import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
        },
        subRoot: {
            flexGrow: 1,
            width: "100%",
            marginTop: 12
        },
        heading: {
            fontSize: 24,
            fontWeight: 'bold',
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title1: {
            align: "left",
           fontSize: 12,
        },
        title2: {
            align: "left",
            marginTop: 24,
        },
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            width: '98%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
            
        },
        TextFieldRoot: {
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'red',
                },
            },
        },
        noStyle: {},
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            
            marginTop: 8, 
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginTop: 14,
            marginLeft: 21,
        },
        iconText: {
            alignSelf: "center",
            marginLeft:20,
            height:13,
            width:40,
            fontSize: 10,
        },
        uploadButton: {
            display: 'block',
            marginTop: 8,
            width: 159,
            height: 40,
            textTransform: 'capitalize',
        }
    }),
);