import { AppBar, Avatar, BottomNavigation, Button, Container, Divider, FormControl, GridList, GridListTile, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Toolbar, Typography,} from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './request-product.style'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from "react-i18next"

export const RequestProductComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [error, setError] = useState(true);
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className = {classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("request_product")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Typography variant="body1" className={ classes.title2 } >
                <b>{t("product_name")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type the product name</Typography>} variant="outlined" />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                    </Typography> }
            </form>
            <Typography variant="body1" className={ classes.title3 } >
                <b>{t("product_company_name")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type the company name</Typography>} variant="outlined" />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                    </Typography> }
            </form>
            <Typography variant="body1" className={ classes.title3 } >
                <b>{t("product_chemical")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="stateName" style = {{fontSize: 12,}}>Type chemical name</InputLabel>
                <OutlinedInput
                    id="stateName"
                    //value={values.stateName}
                    //onChange={handleChange('stateName')}
                    endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        type="submit" 
                        className={classes.expandIcon} 
                        aria-label="search"
                        edge="end"
                    >
                        <ExpandMoreIcon />
                    {/*values.showName ? <Visibility /> : <VisibilityOff />*/}
                    </IconButton>
                    </InputAdornment>
                }
                labelWidth={150}
                />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                    </Typography> }
                </FormControl>
            <Typography variant="body1" className={ classes.title3 } >
                <b>{t("product_image")}</b>
            </Typography>
            
                <Container fixed className={classes.imageContainer}>
                    <Avatar alt="camIcon" src={'../asset/Camera Icon.svg'} className={classes.icon}  />
                    <Typography variant="subtitle2" color="primary" className={ classes.iconText }>
                        {t("camera")}
                    </Typography>
                </Container>
                <Container fixed className = {classes.imageContainer} style = {{marginLeft: 8,}}>
                    <Avatar alt="camIcon" src={'../asset/Gallery Icon.svg'} className={classes.icon}  />
                    <Typography variant="subtitle2" color="primary" className={ classes.iconText } style = {{marginLeft: 22}}>
                        {t("gallery")}
                    </Typography>
                </Container>
                <BottomNavigation
                    showLabels
                    className={classes.bottomNavigation}
                >
                    <Button variant="contained" color="primary" className={classes.requestButton}>
                        {t("product_add_to_shop")}
                    </Button>
                </BottomNavigation>
           
        </div>
    )
}