import { AddProductAction } from "../../action/product/add-product.store";
import { SecurityManager } from "../../security/security.manager";
import { DelarProductDto } from "../../service/dto/product.dto";
import { ProductService } from "../../service/product.service";
import { DelarProductSearchResult, DelarProductSearchResultRes } from "../../service/response/product.response";
import { AddProductStore } from "../../store/product/add-product.store";

const searchForProduct = () => {
    ProductService.getProductList(AddProductStore.getProductSearchDto()).then((res) => {
        AddProductStore.upadteProductList(res.data.data)
        AddProductStore.upadteProductSearchDto(res.data.nextApiReq)
    }).catch(err => {
        console.log(err)
    })
}

const addDelarProductDto = (delarProductSearchResult: DelarProductSearchResult) => {
    AddProductStore.addDelarProductDto(delarProductSearchResult)
}

const deleteDelarProductDto = (delarProductSearchResult: DelarProductSearchResult) => {
    AddProductStore.deleteDelarProductDto(delarProductSearchResult)
}

const saveData = () => {
    const uid = SecurityManager.getUid();
    const delarProductDtoList = AddProductStore.getDelarProductDtoList()?.map(selectedProduct => {
        return {
            delarId: uid,
            productId: selectedProduct.id,
            type: 0,
            status: 1,
            enabled: true,
            removed: false,
            viewCount: 0,
            shareCount: 0,
            active: true
        } as DelarProductDto
    })
    if (delarProductDtoList) {
        ProductService.addDelarProductList(delarProductDtoList)
    }
}

const updateSearchText = (searchText: string) => {
    AddProductStore.updateSearchText(searchText)
}

export const AddProductActor = (action: any, data: any) => {
    switch (action.type) {
        case AddProductAction.SEARCH_FOR_NEW_PRODUCT_RESULT:
            searchForProduct()
            break;
        case AddProductAction.UPDATE_SEARCH_TEXT:
            updateSearchText(data.searchText)
            break;
        case AddProductAction.ADD_DELAR_PRODUCT_DTO:
            addDelarProductDto(data.selectedProduct)
            break;
        case AddProductAction.DELETE_DELAR_PRODUCT_DTO:
            deleteDelarProductDto(data.selectedProduct)
            break;
        case AddProductAction.SAVE_DATA:
            saveData()
            break;
        default:
            break;
    }
}