import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingBottom:10,
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 24,
        },
        title: {
            flexGrow: 1,
            fontSize: 14,
            marginLeft: theme.spacing(2),
        },
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            marginLeft: 16,
            width: '91%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        textField2: {
            align: "left",
            width: "90%",
            height: 47,
            marginTop: 8,
            marginLeft: 16,
        },
        title4: {
            align: "left",
            
            marginLeft: 16,
            fontSize: 12,
            
        },
        searchIcon: {
            padding: 10,
            marginLeft: 16,
        },
        requestButton:{
            width: '91%',
            alignSelf: 'center',
            justifySelf: 'center',
            
            marginLeft: 16,
            marginRight: 16,
            padding: 10,
            textTransform: 'capitalize',
            fontSize: 14,
        },
        bottomNavigation: {
            position: 'fixed',
            marginTop: 73,
            width: '100%',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);