import { Avatar, GridList, GridListTile, Typography } from "@material-ui/core";
import { useRef, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useStyles } from './gallery-picker.style'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

export const GalleryPickerComponent = (props: any) => {
  const _images = useRef<Array<any>>([])

  const [images, setImages] = useState<Array<any>>(_images.current);
  const maxNumber = 8;

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    _images.current = imageList
    setImages(_images.current)
    sendDataBack()
  };

  const classes = useStyles();

  const imageClicked = (input: any) => {
    var x = input.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(x);
    reader.onload = (e) => {

      var img1 = {
        file: x,
        dataURL: e.target.result,
      };
      _images.current = [img1, ...images]

      setImages(_images.current)
      sendDataBack()
    }
  };
  const deleteElement = (elementIndex: any) => {
    let imagesCopy = _images.current
    imagesCopy.splice(elementIndex.index, 1);

    _images.current = imagesCopy
    setImages(_images.current);
    sendDataBack()
  }

  const sendDataBack = () => {
    props.handleUpload(_images.current)
  }

  return (
    <div className="App" style={{ marginTop: 8, }}>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <div className="upload_image-wrapper">
            <GridList cellHeight={79} cols={4}>
              {imageList.map((image, index) => (
                <GridListTile key={index} style={{ width: 79, }}>
                  <HighlightOffIcon key={index} className={classes.imageDelete} onClick={() => { deleteElement({ index }) }} />
                  <img src={image.dataURL} alt="" width="100%" />
                </GridListTile>
              ))}
              <label className={classes.cameraButton} style={{ marginLeft: 8, width: 79, }}>
                <GridListTile className={classes.imageContainer} >

                  <Avatar alt="camIcon" src={'/asset/Camera Icon.svg'} className={classes.icon} />
                  <Typography variant="subtitle2" color="primary" className={classes.iconText}>
                    Camera
                  </Typography>

                </GridListTile>
                <input type="file" id="capture" accept="image/*" capture="camera" style={{ display: 'none' }} onChange={(value) => { imageClicked(value) }} />
              </label>


              <GridListTile className={classes.imageContainer} style={{ marginLeft: 8, width: 79, }} onClick={onImageUpload} {...dragProps}>
                <Avatar alt="camIcon" src={'/asset/Gallery Icon.svg'} className={classes.icon} />
                <Typography variant="subtitle2" color="primary" className={classes.iconText} style={{ marginLeft: 22 }}>
                  Gallery
                </Typography>
              </GridListTile>
            </GridList>


            &nbsp;

          </div>
        )}
      </ImageUploading>
    </div>
  );
}