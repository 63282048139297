import { AppBar, Avatar, Card, CardContent, Container, IconButton, Toolbar, Typography } from "@material-ui/core"
import CallIcon from '@material-ui/icons/Call'
import MessageIcon from '@material-ui/icons/Message'
import React, { useEffect } from "react"
import { useStyles } from "./profile.style"
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { CreateEShopComponent } from './application-cards/create-eshop/create-eshop.component'
import { RejectedComponent } from './application-cards/rejected/rejected.component'
import { PendingComponent } from './application-cards/pending/pending.component'
import { SecurityManager } from "../../../security/security.manager"
import { useTranslation } from "react-i18next"
import { DelarActor } from "../../../actor/delar.actor"
import { DelarAction } from "../../../action/delar.action"
import { useObservable } from "../../../useSubscriber"
import { DelarStore } from "../../../store/delar.store"
import { Media } from "../../../model/media.model"

export const ProfileComponent = () => {
    const { t } = useTranslation();
    let photo: Media = {} as Media
    let url: string = ""

    const classes = useStyles();
    const [delarInfo, _] = useObservable(DelarStore.getDelarInfo(), DelarStore.getDelarInfoObservable())

    useEffect(() => {
        DelarActor({"type": DelarAction.GET_DELAR_INFO},{"delarID": SecurityManager.getUid()})
    }, [])
    if(delarInfo!=null && delarInfo!=undefined)
    {
        if(delarInfo.shopPhotos!=null && delarInfo.shopPhotos !=undefined )
        {
            photo = new Map(Object.entries(delarInfo.shopPhotos)).values().next().value as Media
            if(photo != null && photo != undefined)
            {
                url = new Map(Object.entries(photo.urls)).get('main') as string
                //console.log(url)
            }
        }
    }

    return (
        
        <div className={classes.root}>
            {delarInfo?.shopPhotos && (
                <React.Fragment>
                   <div style={{flex:1, alignItems: 'stretch', position: 'relative'}}>
                        <img src={new Map(Object.entries((new Map(Object.entries(delarInfo.shopPhotos)).values().next().value as Media).urls)).get('main') as string} alt={'profileImage'} className={classes.img}  />
                        <Typography variant="h6" className={classes.title1}>       
                            {delarInfo.shopName.newName}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
           
            {delarInfo?.shopName?.newName && delarInfo?.shopName?.newName.length >0 && url.length <= 0 && (
                <React.Fragment>
                    <div style={{flex:1, alignItems: 'stretch', position: 'relative'}}>
                        <img src={"/asset/DemoShopSubstitute.png"} alt={'shopImage'} className={classes.img}  />
                        <Typography variant="h6" className={classes.title1}>       
                            {delarInfo.shopName.newName}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            <div style={{display:"flex", }}>
                    <Typography variant="h6" className={ classes.title2 } noWrap>
                        <b>{t("details")}</b> 
                    </Typography>
                    <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
            </div>
            {delarInfo?.name && delarInfo?.name.length >0 && (
                <React.Fragment>
                    <div style={{display:"flex", }}>
                        <Avatar alt="editIcon" src={'../asset/userIcon.svg'} className={classes.icon}  />                
                        <Typography variant="subtitle1" className={ classes.title3 } noWrap>
                            {delarInfo.name}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            {delarInfo?.phone && delarInfo?.phone.length >0 &&  (
                <React.Fragment>
                    <div style={{display:"flex"}}>
                        <CallIcon className={classes.icon} style={{opacity: 0.5}} />
                        {delarInfo?.alternatePhone && delarInfo?.alternatePhone.length >0 &&(                
                            <Typography variant="subtitle1" className={ classes.title3 } noWrap>
                                {delarInfo.phone}, {delarInfo.alternatePhone}
                            </Typography>
                        )}
                        {delarInfo?.alternatePhone.length <=0 &&(                
                            <Typography variant="subtitle1" className={ classes.title3 } noWrap>
                                {delarInfo.phone}
                            </Typography>
                        )}
                    </div>
                </React.Fragment>
            )}
            {delarInfo?.shopAddress?.streetLine && delarInfo?.shopAddress?.streetLine.length >0 && delarInfo?.shopAddress?.landMark && delarInfo?.shopAddress?.landMark.length >0 && delarInfo?.shopAddress?.geoAddress?.state?.name && delarInfo?.shopAddress?.geoAddress?.state?.name.length >0 && delarInfo?.shopAddress?.geoAddress?.district?.name && delarInfo?.shopAddress?.geoAddress?.district?.name.length >0 &&(
                <React.Fragment>
                    <div style={{display:"flex"}}>
                        <Avatar alt="editIcon" src={'../asset/locationIcon.svg'} className={classes.icon}  />                
                        <Typography variant="subtitle1" className={ classes.title3 } noWrap>
                            {delarInfo.shopAddress.landMark}, {delarInfo.shopAddress.streetLine}, {delarInfo.shopAddress.geoAddress.district.name}, {delarInfo.shopAddress.geoAddress.state.name}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            {delarInfo?.pesticideLiscence?.licence?.number && delarInfo?.pesticideLiscence?.licence?.number.length >0 && (
                <React.Fragment>
                    <div style={{display:"flex"}}>
                        <i className={`fas fa-spider ${classes.icon}`} style = {{opacity: 0.5}}></i>                 
                        <Typography variant="subtitle1" className={ classes.title3 } style={{color:'#FFCC00',}} noWrap>
                            {delarInfo.pesticideLiscence.licence.number}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            {delarInfo?.fertilizerLiscence?.licence?.number && delarInfo?.fertilizerLiscence?.licence?.number.length >0 && (
                <React.Fragment>
                    <div style={{display:"flex"}}>
                        <Avatar alt="editIcon" src={'../asset/userIcon.svg'} className={classes.icon}  />                
                        <Typography variant="subtitle1" className={ classes.title3 } noWrap>
                            {delarInfo.fertilizerLiscence.licence.number}  
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            <div style={{display:"flex"}}>
                    <i className={`fas fa-spa ${classes.icon}`} style = {{opacity: 0.5}}></i>               
                    <Typography variant="subtitle1" className={ classes.title3 } style={{color:'#1078FF',}} noWrap>
                        {t("profile_add_seed_license")}
                    </Typography>
            </div>
            {delarInfo?.intro && delarInfo?.intro.length >0 && (
                <React.Fragment>
                    <div style={{display:"flex"}}>
                        <MessageIcon className={classes.icon} style={{opacity: 0.5}} />                
                        <Typography paragraph variant="subtitle1" className={ classes.title3 } noWrap>
                            {delarInfo.intro}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}