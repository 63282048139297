import { AndroidConnerctor } from "../android.connecter"
import { DELAR_APP_LANG_CODE, DELAR_APP_SESSION } from "../constant/base.constant"
import { DelarStatus } from "../enum/delar-status.enum"
import { Session } from "../model/session.model"

const Android = (window as any).Android

export const SecurityManager = {

    loggedIn: (): boolean => {
        try {
            let session = JSON.parse('{}') as Session
            if (Android) {
                session = JSON.parse(AndroidConnerctor.getSession() || '{}') as Session
            } else {
                session = JSON.parse(localStorage.getItem(DELAR_APP_SESSION) || '{}') as Session
            }

            if (session && session.token.length > 0) {
                return true
            } else {
                return false
            }
        } catch (err) {
            return false
        }
    },

    setSession(session: Session) {
        if (Android) {
            AndroidConnerctor.setSession(session)
        } else {
            localStorage.setItem(DELAR_APP_SESSION, JSON.stringify(session))
        }
    },

    setLanguage(lang: string) {
        if (Android) {
            AndroidConnerctor.setLanguage(lang)
        } else {
            localStorage.setItem(DELAR_APP_LANG_CODE, lang)
        }
    },

    getToken: (): string => {
        let session = JSON.parse('{}') as Session
        if (Android) {
            session = JSON.parse(AndroidConnerctor.getSession() || '{}') as Session
        } else {
            session = JSON.parse(localStorage.getItem(DELAR_APP_SESSION) || '{}') as Session
        }

        if (session && session.token && session.token.length > 0) {
            return session.token
        } else {
            return ''
        }
    },

    getUid: (): string => {
        let session = JSON.parse('{}') as Session
        if (Android) {
            session = JSON.parse(AndroidConnerctor.getSession() || '{}') as Session
        } else {
            session = JSON.parse(localStorage.getItem(DELAR_APP_SESSION) || '{}') as Session
        }

        console.log(session)

        if (session && session.id && session.id.length > 0) {
            return session.id
        } else {
            return ''
        }
    },

    getVerified: (): number => {
        try {
            let session = JSON.parse('{}') as Session
            if (Android) {
                session = JSON.parse(AndroidConnerctor.getSession() || '{}') as Session
            } else {
                session = JSON.parse(localStorage.getItem(DELAR_APP_SESSION) || '{}') as Session
            }
            if (session && session.id && session.id.length > 0) {
                return session.verified
            } else {
                return DelarStatus.NotInit
            }
        } catch {
            return DelarStatus.NotInit
        }

    },

    logout() {
        try {
            localStorage.removeItem(DELAR_APP_SESSION)
            AndroidConnerctor.removeSession()
            window.location.reload()
        } catch (err) {
            console.error(err)
        }
    },
    getLanguage: (): string => {
        if (Android) {
            return AndroidConnerctor.getLanguage()
        } else {
            return localStorage.getItem(DELAR_APP_LANG_CODE)
        }
    },
}