import './App.css';
import { useHttpIntercepter } from './di/http.intercepter';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import SecureRoute from './security/secure.route';
import { MainComponent } from './ui/main/main.componet';
import { LanguageSelectionComponent } from './ui/on-boarding/language-selection/language-selection.component';
import { RegisterComponent } from './ui/on-boarding/register/register.component';
import { ValidateOtpComponent } from './ui/on-boarding/validate-otp/validate-otp.component';
import { IntroComponent } from './ui/on-boarding/intro/intro.component';
import { EditDetailComponent } from './ui/on-boarding/edit-detail/edit-detail.component';
import { HomeComponent } from './ui/main/home/home.component';
import { ProductComponent } from './ui/main/product/product.component';
import { ProfileComponent } from './ui/main/profile/profile.component';
import { SettingComponent } from './ui/common/setting/setting.component';
import { AboutUsComponent } from './ui/common/about-us/about-us.component';
import { TermsPoliciesComponent } from './ui/common/terms-policies/terms-policies.component';
import { NotificationComponent } from './ui/common/notification/notification.component';
import { DelarDetailComponent } from './ui/on-boarding/delar-detail/delar-detail.component';
import { EditProfileComponent } from './ui/main/profile/edit-profile/edit-profile.component';
import { ProductInfoComponent } from './ui/main/product/product-info/product-info.component';
import { EditLicenseComponent } from './ui/main/profile/edit-profile/edit-license/edit-license.component'
import { EditAddressComponent } from './ui/main/profile/edit-profile/edit-address/edit-address.component'
import { EditShopComponent } from './ui/main/profile/edit-profile/edit-shop/edit-shop.component'
import { UploadPictureComponent } from './ui/main/profile/edit-profile/upload-picture/upload-picture.component'
import { RequestProductComponent } from './ui/main/product/new-product/request-product.component'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GalleryPickerComponent } from './ui/Image_Picker/gallery-picker.component'
import { SecurityManager } from './security/security.manager';
import { useFirebaseStorageIntercepter } from './di/upload.service';
import { AddProductComponent } from './ui/main/product/add-product/add-product.compont';
import { FilterComponent } from './ui/main/product/filter-page/filter.component'
import { AddVillageComponent } from './ui/on-boarding/delar-detail/deliverable-village/deliverable-village-add.component';
import { NotDeliverable } from './ui/on-boarding/delar-detail/deliverable-village/not-deliverable.component';

function App() {

  const { i18n } = useTranslation();

  useEffect(() => {
    const language = SecurityManager.getLanguage()
    if (language.length > 0) {
      i18n.changeLanguage(language)
    } else {
      i18n.changeLanguage("en")
    }
  }, [])

  useHttpIntercepter()
  useFirebaseStorageIntercepter()

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/on-board" element={<Outlet />} >
            <Route path="/" element={<LanguageSelectionComponent />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/validate-otp" element={<ValidateOtpComponent />} />
            <Route path="/intro" element={<IntroComponent />} />
            <Route path="/detail" element={<DelarDetailComponent />} />
            <Route path="/edit-detail" element={<EditDetailComponent />} />
            <Route path="/add-village" element={<AddVillageComponent />} />
            <Route path = "/not-deliverable" element={<NotDeliverable />} />
          </Route>

          <Route path="/verification-rejected" element={<h1>Hello Rejected</h1>} />

          <SecureRoute path="/" element={<MainComponent />} >
            <Route path="/home" element={<HomeComponent />} />
            <Route path="/product" element={<ProductComponent />} />
            <Route path="/profile" element={<ProfileComponent />} />
          </SecureRoute>

          <SecureRoute path="/product/:productId" element={<ProductInfoComponent />} />
          <SecureRoute path="/product/add" element={<AddProductComponent />} />
          <SecureRoute path="/product/new-request" element={<RequestProductComponent />} />

          <SecureRoute path="/profile/edit-profile" element={<EditProfileComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-license" element={<EditLicenseComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-address" element={<EditAddressComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/edit-shop" element={<EditShopComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/profile/edit-profile/upload-picture" element={<UploadPictureComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/product/request-product" element={< RequestProductComponent />} /> {/* added by deborathi*/}
          <SecureRoute path="/product/filter" element={< FilterComponent />} /> {/* added by deborathi*/}
          
          
          <SecureRoute path="/gallery" element={< GalleryPickerComponent />} /> {/* added by deborathi*/}

          <SecureRoute path="/inventary" element={<Outlet />} >
            <Route path="/" element={<Outlet />} />
            <Route path="/filter" element={<Outlet />} />
          </SecureRoute>

          <SecureRoute path="/profile/:userId" element={<MainComponent />} >
            <Route path="/edit" element={<Outlet />} />
            <Route path="/edit-address" element={<Outlet />} />
            <Route path="/edit-licence" element={<Outlet />} />
            <Route path="/edit-shop-name" element={<Outlet />} />
          </SecureRoute>

          <SecureRoute path="/notification" element={<NotificationComponent />} />

          <SecureRoute path="/settings" element={<SettingComponent />} />

          <SecureRoute path="/terms-policies" element={<TermsPoliciesComponent />} />
          <SecureRoute path="/about-us" element={<AboutUsComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
