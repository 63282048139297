import { Grid, Typography } from "@material-ui/core"
import React, {  } from "react"
import Button from '@material-ui/core/Button';
import { useStyles } from "./language-selection.style"
import { Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRouteManager } from "../../../router/route.manager";
import { RegistrationAction } from "../../../action/registration.action";
import { RegistrationActor } from "../../../actor/registration.actor";
import { LangCode } from "../../../enum/lang-code.enum";

export const LanguageSelectionComponent = () => {

        const { t } = useTranslation();
        const routeManager = useRouteManager();

        const classes = useStyles();

        const updateLanguage = (langCode: string) => {
                RegistrationActor({ "type": RegistrationAction.SET_LANGUAGE }, { "langCode": langCode })
                routeManager.openRegister()
        }

        return (
                <React.Fragment>
                        <Grid container direction="column" alignItems="center" justify="center">
                                <Avatar alt="logof" src={'../asset/logof.png'} className={classes.kpLogo} />
                                <Typography variant="h6" className={classes.langTitle1}>
                                        {t("select_your_language")}
                                </Typography>
                                <Typography variant="h6" className={classes.langTitle2}>
                                        अपनी भाषा चुनिए
                                </Typography>
                        <Button variant="outlined" className={classes.engButton} color="primary"
                                        onClick={() => updateLanguage(LangCode.English)}>
                                        English
                        </Button>
                        <Button variant="outlined" className={classes.hinButton} color="primary"
                                        onClick={() => updateLanguage(LangCode.Hindi)}>
                                        हिन्दी
                        </Button>
                        <Button variant="outlined" className={classes.gujButton} color="primary"
                                        onClick={() => updateLanguage(LangCode.Gujurati)}>
                                        ગુજરાતી
                        </Button>
                        </Grid>
                </React.Fragment>
        )
}