import { BehaviorSubject, Observable } from "rxjs"
import { ApiError } from "../model/error.model"
import { Category } from "../service/response/category.response"
import { Company } from "../service/response/company.response"

let _companyList = new BehaviorSubject<Array<Company> | ApiError>([])
let _cropCategoryList = new BehaviorSubject<Array<Category> | ApiError>(new Array())

export const FilterStore = {
    getCompanyList: (): Array<Company> | ApiError => {
        return _companyList.value;
    },
    getCropCategoryList: (): Array<Category> | ApiError => {
        return _cropCategoryList.value;
    },
    setCompanyList: (newCompanyList: Array<Company> | ApiError) => {
        _companyList.next({ ...newCompanyList })
    },
    setCropCategoryList: (newCropCategoryList: Array<Category> | ApiError) => {
        _cropCategoryList.next({ ...newCropCategoryList })
    },
    getCompanyListObservable: (): Observable<Array<Company> | ApiError> => {
        return _companyList.asObservable()
    },
    getCropCategoryListObservable: (): Observable<Array<Category> | ApiError> => {
        return _cropCategoryList.asObservable()
    },
}