import { AppBar, Avatar, BottomNavigation, Button, Container, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Toolbar, Typography,} from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-address.style'
import SearchIcon from '@material-ui/icons/Search'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useObservable } from "../../../../../useSubscriber"
import { EditProfileStore } from "../../../../../store/edit-profile.store"
import { EditProfileActor } from "../../../../../actor/edit-profile.actor"
import { EditProfileAction } from "../../../../../action/edit-profile.action"
import { StatePickerComponent } from "../../../../common/address-picker/state-picker.component"
import { State } from "../../../../../service/response/state.response"
import { DistrictPickerComponent } from "../../../../common/address-picker/district-picker.component"
import { SubDistrictPickerComponent } from "../../../../common/address-picker/sub-district-picker.component"
import { VillagePickerComponent } from "../../../../common/address-picker/village-picker.component"
import { District } from "../../../../../service/response/district.response"
import { SubDistrict } from "../../../../../service/response/sub-district.response"
import { Village } from "../../../../../service/response/village.response"
import { SecurityManager } from "../../../../../security/security.manager"
import { useTranslation } from "react-i18next"

export const EditAddressComponent = () => {

    const classes = useStyles();
    const [error, setError] = useState(true);
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(EditProfileStore.getDelarDtoError(), EditProfileStore.getDelarDtoErrorObservable())
    const { t } = useTranslation();
    const handleChangeStreetAddress = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_STREET_ADDRESS }, { "streetLine": e.target.value })
    };

    const handleChangeLandMark = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_LANDMARK }, { "landMark": e.target.value })
    };
    
    const setState = (state: State) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_STATE }, { "state": state })
    };

    const setDistrict = (district: District) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DISTRICT }, { "district": district })
    };

    const setSubDistrict = (subDistrict: SubDistrict) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_SUB_DISTRICT }, { "subDistrict": subDistrict })
    };

    const setVillage = (village: Village) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_VILLAGE }, { "village": village })
    };

    const handleChangePinCode = (e: any) => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_PINCODE }, { "pincode": e.target.value })
    };

    const handleUpdate = () => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid() })
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("change_address")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Typography variant="body1" className={ classes.title4 } style = {{marginTop: 16,}}>
                <b>{t("street_address")}</b>
            </Typography>
            <form className={classes.formField}  noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.shopAddress?.streetLine} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your street address</Typography>} variant="outlined" onChange={handleChangeStreetAddress} />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </form>
            <Typography variant="body1" className={ classes.title4 } >
                <b>{t("landmark")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.shopAddress?.landMark} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type nearby landmark</Typography>} variant="outlined" onChange={handleChangeLandMark} />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </form>
            <Typography variant="body1" className={ classes.title4 } >
                <b>{t("state")}</b>
            </Typography>
            <StatePickerComponent 
            setState={setState}/>
           {delarDto?.shopAddress?.geoAddress?.state?.name && delarDto?.shopAddress?.geoAddress?.state?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title4} >
                        <b>{t("district")}</b>
                    </Typography>

                    <DistrictPickerComponent
                        setDistrict={setDistrict}
                        stateCode={delarDto.shopAddress.geoAddress.state.code} />
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.district?.name && delarDto?.shopAddress?.geoAddress?.district?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title4} >
                        <b>{t("city")}</b>
                    </Typography>

                    < SubDistrictPickerComponent
                        setSubDistrict={setSubDistrict}
                        districtCode={delarDto.shopAddress.geoAddress.district.code} />
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.subDistrict?.name && delarDto?.shopAddress?.geoAddress?.subDistrict?.name.length > 0 && (
                <React.Fragment >
                    <Typography variant="body1" className={classes.title4} >
                        <b>{t("village")}</b>
                    </Typography>
                    <VillagePickerComponent
                        setVillage={setVillage}
                        subDistrictCode={delarDto.shopAddress.geoAddress.subDistrict.code} />
                </React.Fragment>
            )}
            <Typography variant="body1" className={ classes.title4 } >
                <b>{t("pincode")}</b>
            </Typography>
            <form className={classes.formField} style = {{marginBottom: 100,}} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.shopAddress?.pinCode} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">123456</Typography>} variant="outlined" onChange={handleChangePinCode} />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </form>
            {/*<Divider style={{marginTop:50,}}/>*/}
            <BottomNavigation className = {classes.bottomNavigation}>
                <Button variant="contained" color="primary" className={classes.requestButton} onClick={handleUpdate}>
                        {t("send_request")}
                    </Button>
            </BottomNavigation>
                
            
        </div>
    )
}