import { FormControl, InputAdornment, OutlinedInput, TextField, Typography } from "@material-ui/core"
import React, {useEffect, useState} from "react"
import { DelarAction } from "../../../../action/delar.action";
import { DelarActor } from "../../../../actor/delar.actor";
import { DelarStore } from "../../../../store/delar.store";
import { useObservable } from "../../../../useSubscriber";
import { useStyles } from "./personal-detail.style"
import { useTranslation } from "react-i18next"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
export const PersonalDetailComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [delarDto, dtoe] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())

    const handleChangeName = (e: any) => {
        DelarActor({"type": DelarAction.UPDATE_NAME}, {"name": e.target.value})
    };

    const handleChangeAlternatePhone = (e: any) => {
        DelarActor({"type": DelarAction.UPDATE_ALTERNATE_NUMBER}, {"alternatePhone": e.target.value})
    };

    const handleChangeIntro = (e: any) => {
        DelarActor({"type": DelarAction.UPDATE_INTRO}, {"intro": e.target.value})
    };

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={ classes.title1 } >
                <b>{t("onboarding_personal_details")}</b>
            </Typography>
            
                <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } style = {{marginTop: 6,}}>
                        <b>{t("onboarding_personal_details_name")}</b>
                    </Typography>
                    <form className={classes.formField} noValidate autoComplete="off">
                        <TextField 
                            value={delarDto?.name} 
                            id="outlined-basic" 
                            className={classes.textField1} 
                            label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your Name</Typography>} 
                            variant="outlined" 
                            classes = {{
                                root: delarDtoError?.name.error?classes.TextFieldRoot: classes.noStyle,
                            }}
                            onChange = {handleChangeName}/>
                        {delarDtoError?.name.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.name?.msg}
                    </Typography> }
                    </form>
                    
                </React.Fragment>
            
            
                <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } >
                        <b>{t("onboarding_personal_details_alternate_phone_no")}</b>
                    </Typography>
                    <FormControl  className={classes.formField} variant="outlined">
                        <OutlinedInput
                            //id="input-phone-number"
                            value={delarDto?.alternatePhone}

                            type="number"
                            onChange={handleChangeAlternatePhone}
                            classes = {{
                                adornedStart: classes.adornedStart,
                                input: classes.input,
                                root: delarDtoError?.alternatePhone.error?classes.TextFieldRoot: classes.noStyle,
                            }}
                           startAdornment={<InputAdornment position="start" >+91</InputAdornment>}
                        />
                        {delarDtoError?.alternatePhone.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.alternatePhone?.msg}
                        </Typography> }
                    </FormControl>
                    
                </React.Fragment>
            
            
                <React.Fragment>
                    <Typography variant="body1" className={ classes.title2 } >
                        <b>{t("onboarding_personal_details_introduction")}</b>
                    </Typography>
                    <form className={classes.formField} style = {{paddingBottom: 100}} noValidate autoComplete="off">
                        <TextField
                            className={classes.textField1}
                            id="outlined-multiline-static"
                            multiline
                            label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Give your introduction</Typography>}
                            rows={4}
                            value={delarDto?.intro}
                            classes = {{
                                root: delarDtoError?.intro.error?classes.TextFieldRoot: classes.noStyle,
                            }}
                            onChange={handleChangeIntro}
                            variant="outlined"
                        />  
                        {delarDtoError?.intro.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.intro?.msg}
                        </Typography> }
                    </form>
                </React.Fragment>
            
        </div>
    )
}