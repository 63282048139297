import { ProductAction } from "../../action/product/product.store";
import { ProductService } from "../../service/product.service";
import { ProductStore } from "../../store/product/product.store";

const getDelarProduct = () => {
    ProductService.getDelarProductList(ProductStore.getDelarProductSearchDto()).then((res) => {
        //console.log(res.data.data.length)
        ProductStore.upadteDelarProductList(res.data.data)
        ProductStore.upadteDelarProductSearchDto(res.data.nextApiReq)
    }).catch(err => {
        console.log(err)
    })
}

const updateSearchText = (searchText: string) => {
    ProductStore.updateSearchText(searchText)
}
const updateCompanyId  = (companyIds: Array<string>) => {
    ProductStore.updateCompanyId(companyIds)
}
const updateAvailability  = (available: number) => {
    ProductStore.updateAvailability(available)
}
const openDelarProductInfo = (delarProductID: string) => {
    ProductService.getDelarProductById(delarProductID).then((res) => {
        ProductStore.upadteDelarProductInfo(res.data)
    }).catch(err => {
        console.log(err)
    })
}

export const ProductActor = (action: any, data: any) => {
    switch (action.type) {
        case ProductAction.GET_LIST:
            getDelarProduct()
            break;
        case ProductAction.UPDATE_SEARCH_TEXT:
            updateSearchText(data.searchText)
            break;
        case ProductAction.OPEN_PRODUCT_INFO:
            openDelarProductInfo(data.delarProductID)
            break;
        case ProductAction.UPDATE_COMPANY_ID:
            updateCompanyId(data.companyIds)
            break;
        case ProductAction.UPDATE_AVAILABILITY:
            updateAvailability(data.avability)
            break;
        default:
            break;
    }
}


