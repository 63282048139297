import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { useEffect } from "react";

export const useFirebaseStorageIntercepter = () => {
    useEffect(() => {
        // for development
        const firebaseConfig = {
            apiKey: "AIzaSyBFsxdhPTtmHX5rJvNNYJp1RQNlPVRVNfw",
            authDomain: "khetipoint-dev.firebaseapp.com",
            projectId: "khetipoint-dev",
            storageBucket: "khetipoint-dev.appspot.com",
            messagingSenderId: "883382868669",
            appId: "1:883382868669:web:6949f86e1c90bc9d3fb5d4",
            measurementId: "G-NPHKLBVR0R",
        };

        //for production
        // var firebaseConfig = {
        //   apiKey: "AIzaSyDXCkg6MC85dhMDnO6sHE0mDe4NZzF3ySw",
        //   authDomain: "kheti-point-2020.firebaseapp.com",
        //   databaseURL: "https://kheti-point-2020.firebaseio.com",
        //   projectId: "kheti-point-2020",
        //   storageBucket: "kheti-point-2020.appspot.com",
        //   messagingSenderId: "1049286782102",
        //   appId: "1:1049286782102:web:d66792df2e206de20266b7",
        //   measurementId: "G-4V55DDBQEQ"
        // };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
    }, [])
}