import React, { useState } from "react"
import { AppBar, Avatar, BottomNavigation, BottomNavigationAction, IconButton, Toolbar, Typography } from "@material-ui/core"
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Outlet } from "react-router-dom"
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import { useStyles } from "./main.style"
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../router/route.manager"
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InfoIcon from '@material-ui/icons/Info';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StarIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const MainComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeManager = useRouteManager();

  const [value, setValue] = useState(0);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key="whats_app_share">
          <Divider />
          <ListItemIcon><WhatsAppIcon /></ListItemIcon>
          <ListItemText primary={t("main_share_dukaan")} />
        </ListItem>
        <ListItem button key="whats_app_share"
         onClick={() => {routeManager.openAboutUs()}}>
          <Divider />
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary={t("about_khetipoint")} />
        </ListItem>
        <ListItem button key="whats_app_share">
          <Divider />
          <ListItemIcon><StarIcon /></ListItemIcon>
          <ListItemText primary={t("main_rate_khetipoint")} />
        </ListItem>
        <ListItem button key="whats_app_share">
          <Divider />
          <ListItemIcon><ScreenShareIcon /></ListItemIcon>
          <ListItemText primary={t("main_recommend")} />
        </ListItem>
        <ListItem button key="whats_app_share">
          <Divider />
          <ListItemIcon><HeadsetMicIcon /></ListItemIcon>
          <ListItemText primary={t("main_help")} />
        </ListItem>
      </List>
      <Divider variant="fullWidth"
        orientation="vertical" />
      <List>
        <Divider />
        <ListItem button key="whats_app_share"
        onClick={() => {routeManager.openSetting()}}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={t("settings")} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <React.Fragment>

      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
            onClick={toggleDrawer("left", true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            KhetiPoint
                    </Typography>

          <IconButton edge="end" 
          onClick={() => {routeManager.openNotification()}}
          className={classes.notificationButton} color="inherit" aria-label="menu">
            <NotificationsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>

      <Outlet />

      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.bottomNavigation}
      >
        <BottomNavigationAction onClick={() => routeManager.openHome()} label={t("my_shop")} icon={<RestoreIcon />} />
        <BottomNavigationAction onClick={() => routeManager.openProduct()} label={t("product")} icon={<FavoriteIcon />} />
        <BottomNavigationAction onClick={() => routeManager.openProfile()} label={t("profile")} icon={<LocationOnIcon />} />
      </BottomNavigation>
    </React.Fragment>
  )
}