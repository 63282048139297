import { useTranslation } from "react-i18next"
import { RegistrationAction } from "../../../action/registration.action"
import { RegistrationActor } from "../../../actor/registration.actor"
import { useRouteManager } from "../../../router/route.manager"
import { RegistrationStore } from "../../../store/registration.store"
import { useObservable } from "../../../useSubscriber"
import { useStyles } from "./register.style"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useState, useEffect } from "react"
import { Typography, FormControl, OutlinedInput, InputAdornment, Button } from "@material-ui/core"


export const RegisterComponent = () => {
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [genearteOtpRes, generateOtpError] = useObservable(RegistrationStore.getGenearteOtpRes(), RegistrationStore.getGenearteOtpResObservable())

    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [optSending, setOptSending] = useState(false);

    const handleChange = (event: any) => {
        setPhoneNumber(event.target.value)
    };

    useEffect(() => {
        if (!generateOtpError.error && genearteOtpRes && optSending) {
            routeManager.openOTPValidation()
        }
    }, [genearteOtpRes, generateOtpError])

    const sendOTP = () => {
        setOptSending(true)
        RegistrationActor({ "type": RegistrationAction.SEND_OTP }, { "phone": phoneNumber })
    }
    const [error, setError] = useState(true);
    return (

        <div className={classes.root}>
            <ArrowBackIcon className={classes.backIcon} />
            <Typography variant="h4" className={classes.title1} >
                <b>{t("register_mobile_no")}</b>
            </Typography>
            <Typography variant="subtitle2" className={classes.title2} >
                {t("register_mobile_no_input")}
                </Typography>
                <FormControl className={classes.numberMargin} variant="outlined">
                    <OutlinedInput
                        id="input-phone-number"
                        value={phoneNumber}
                        onChange={(e: any) => handleChange(e)}
                        classes = {{
                                adornedStart: classes.adornedStart,
                                input: classes.input,
                            }}
                        startAdornment={<InputAdornment position="start" >+91</InputAdornment>}
                    />
                    {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                    </Typography> }
                </FormControl>
                <Button variant="contained" color="primary" className={classes.nextButton}
                    onClick={() => sendOTP()}>
                    {t("next")}
                </Button>
        </div>

    )
}