import Axios, { AxiosResponse } from "axios";
import { DelarDto } from "./dto/delar.dto";
import { Delar } from "./response/delar.response";
import { ValidateOTPRes } from "./response/registration.response";

export const DelarService = {
    getDelarById: (delarId: string): Promise<AxiosResponse<Delar>> => {
        return Axios.get<Delar>(`/delar/${delarId}`)
    },
    updateDelarById: (delarId: string, delarDto: DelarDto): Promise<AxiosResponse<Delar>> => {
        return Axios.put<Delar>(`/delar/${delarId}`, delarDto)
    },
    checkDelarStatus: (delarId: string): Promise<AxiosResponse<ValidateOTPRes>> => {
        return Axios.get<ValidateOTPRes>(`/delar/${delarId}/check-status`)
    },
}