import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
           
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 40,
            marginRight: 10,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#fff',
            
            marginRight: theme.spacing(1),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.spacing(3),
              width: 'auto',
            },
          },
          searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#000',
            opacity: 0.5,
          },
        inputRoot: {
            color: '#000',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '20ch',
            },
        },
        topButtonCard: {
            width: '95%',
            position: 'static',
           
            padding: "12px 0px 12px 0px",
            paddingLeft: 16,
            
        },
        chip: {
            marginRight: 12,
            marginBottom: 3,
            backgroundColor: '#fff',
            border: '0.5px solid #7E8184',
            fontSize: 11,
        },
        productImage: {
            width: 60, height: 60,
        },
        title: {
            flexGrow: 1,
            align: "left",
            fontSize: 14,
            marginLeft: theme.spacing(1),
        },
        productName: {
            fontSize: 12,
            fontWeight: 600,
        },
        productDescription: {
            fontSize: 11,
            opacity: 0.8,
        },
        nextButton: {
            width: "80%",
            margin: "auto",
            display: "flex",
            textTransform: 'capitalize',
            background: '#0E4BB4',
            fontSize: 14,
        },
        bottomNavigation: {
            marginTop: 10,
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);