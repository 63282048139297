import { createStyles, makeStyles, Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            
            
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginTop: 14,
            marginLeft: 21,
            
        },
        iconText: {
            alignSelf: "center",
            marginLeft:20,
            height:13,
            width:40,
            fontSize: 10,
        },
        gridTiles: {
           width: 79,
        },
        imageDelete: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 2,
        },
        cameraButton: {
            
        },
    }),
);