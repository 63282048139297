import { AppBar, List, ListItem, Button, ListItemAvatar, ListItemText, Divider, Avatar, Container, Link, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './notification.style'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
const notifications = [
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.',
        time: '10:00 A.M.',
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
    {
        img1: '../asset/img1.jpg',
        img2: '../asset/img1.jpg',
        time: '10:00 A.M.',
        message: 'Someone replied on your query. "Namaste Vimal, here is the solution to your query.'
    },
];
export const NotificationComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
        
            <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <Typography variant="h6" className={classes.title}>
                        {t("notifications")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
            {notifications.map((step,index) => {
                    const labelId = '' + index;
                    return (
                        <>
                        <ListItem key={index} style = {{position: 'relative', paddingLeft: 16,}}>
                            
                        <ListItemAvatar style = {{ top: 0, width: 52, height: 119  }}>
                            <img style = {{width: 40, height: 40,}}
                                alt={`Avatar ${step.img1}`}
                                src={step.img1}
                            />
                        </ListItemAvatar>
                        <ListItemText disableTypography id={labelId} primary={<div className = {classes.primaryContainer}>
                            <Typography variant = "body2" className = {classes.heading}>
                                {t("query_solved")}
                            </Typography>
                            <Typography variant = "body2" className = {classes.time}>{step.time}</Typography>
                            
                            </div>} 
                            secondary={<div className = {classes.secondaryHolder}>

                                    <div className = {classes.secondaryContainer}>
                                    <Typography variant = "subtitle1" className = {classes.description}>
                                        {step.message}
                                    </Typography>
                                    <img style = {{width: 27, height: 27, marginLeft: 8,}} src = {step.img2} />
                                </div>
                                <div className = {classes.buttonHolder}>
                                <Button variant="outlined" color="primary" className={classes.button} style = {{color: '#0e4bb4', fontWeight: 700, borderWidth: 1,}}>
                                        {t("secondary_button")}
                                    </Button>
                                    <Button variant="contained" color="primary" className={classes.button} style = {{marginRight: 0, backgroundColor: '#0e4bb4',}}>
                                        {t("primary_button")}
                                    </Button>
                                </div>
                                </div>
                            }/>
                            {/*<div style = {{background: '#ccc',}}>
                                <Typography variant = "body1" className = {classes.time}>{step.time}</Typography>
                                
                    </div>*/}
                        
                        </ListItem>
                        <Divider />
                        </>
                    );
                })}
            </List>
            </React.Fragment>
        
    )
}