import { AppBar, Avatar, BottomNavigation, Button, Card, Checkbox, Chip, Divider, InputBase, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './add-product.style'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { AddProductActor } from "../../../../actor/product/add-product.store"
import { AddProductAction } from "../../../../action/product/add-product.store"
import { AddProductStore } from "../../../../store/product/add-product.store"
import { useObservable } from "../../../../useSubscriber"
import { DelarProductSearchResult } from "../../../../service/response/product.response"
import { Media } from "../../../../model/media.model"
import CloseIcon from '@material-ui/icons/Close';
import InfinitScroll from 'react-infinite-scroll-component';
import LoadingComponent from "../../../common/loading/loading.component"
import { useTranslation } from "react-i18next"

export const AddProductComponent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [productList, _] = useObservable(AddProductStore.getProductList(), AddProductStore.getProductListObservable())
    const [productData, setProductData] = useState<Array<DelarProductSearchResult>>([])
    const [selectedProductList] = useObservable(AddProductStore.getDelarProductDtoList(), AddProductStore.getDelarProductDtoListObservable())
    const [selectedProductData, setSelectedProductData] = useState<Array<DelarProductSearchResult>>([])
    const [isSearchTextChanged, setIsSearchTextChanged] = useState(false);
    const timeOut = useRef<any>(0)

    useEffect(() => {
        AddProductActor({ "type": AddProductAction.SEARCH_FOR_NEW_PRODUCT_RESULT }, {})
    }, [])

    useEffect(() => {
        if(isSearchTextChanged)
        {  
            if (productList.length>0) {
                var newProducts: Array<DelarProductSearchResult> = Object.keys(productList).map(function (index: any) {
                    let newProduct = (productList as DelarProductSearchResult[])[index]
                    return newProduct;
                });
                setProductData([].concat(newProducts))
            } else {
                setProductData([])
            }
        }
        else
        {
            //console.log(delarProductList)
            if (productList) {
                var newProducts: Array<DelarProductSearchResult> = Object.keys(productList).map(function (index: any) {
                    let newProduct = (productList as DelarProductSearchResult[])[index]
                    return newProduct;
                });
                setProductData(productData.concat(newProducts))
            } else {
                setProductData([])
            }
        }
    }, [productList])

    useEffect(() => {
        if (selectedProductList) {
            var newProducts: Array<DelarProductSearchResult> = Object.keys(selectedProductList).map(function (index: any) {
                let product = (productList as DelarProductSearchResult[])[index]
                return product;
            });
            setSelectedProductData(newProducts)
        } else {
            setSelectedProductData([])
        }
    }, [selectedProductList])

    const handleSearch = (e: any) => {
        setIsSearchTextChanged(true);
        if(e.target.value.length === 0)
        {
            setProductData([]);
            setIsSearchTextChanged(false);
        }
        AddProductActor({ "type": AddProductAction.UPDATE_SEARCH_TEXT }, { "searchText": e.target.value })
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
            AddProductActor({ "type": AddProductAction.SEARCH_FOR_NEW_PRODUCT_RESULT }, {})
        }, 500);
    }

    const handleToggle = (event: any, product: DelarProductSearchResult) => {
        if (event.target.checked) {
            AddProductActor({ "type": AddProductAction.ADD_DELAR_PRODUCT_DTO }, { selectedProduct: product })
        } else {
            AddProductActor({ "type": AddProductAction.DELETE_DELAR_PRODUCT_DTO }, { selectedProduct: product })
        }
    };

    const handleDelete = (product: DelarProductSearchResult) => () => {
        AddProductActor({ "type": AddProductAction.DELETE_DELAR_PRODUCT_DTO }, { selectedProduct: product })
    };

    const handleUpdate = () => {
        AddProductActor({ "type": AddProductAction.SAVE_DATA }, {})
    };
    const fetchNextProducts = () => {
        setIsSearchTextChanged(false);
        AddProductActor({ "type": AddProductAction.SEARCH_FOR_NEW_PRODUCT_RESULT }, {})
    };
    return (
        <div className = {classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} />
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <Card className={classes.topButtonCard} variant="outlined">
                {selectedProductData && selectedProductData.length > 0 && (
                    <React.Fragment>
                        {selectedProductData.map((selectedProduct, index) => {
                            return (
                                <Chip
                                    key={selectedProduct.id}
                                    label={selectedProduct.name}
                                    onDelete={handleDelete(selectedProduct)}
                                    className = {classes.chip}
                                    deleteIcon = {<CloseIcon style = {{color: '#000',}}/>}
                                />
                            );
                        })}
                    </React.Fragment>
                )}
            </Card>

            <InfinitScroll
                dataLength = {productData.length}
                next = {fetchNextProducts}
                hasMore = {true}
                loader={<LoadingComponent/>}
            >
            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 40, }}>
                {productData.map((product, index) => {
                    const labelId = `${product.id}`
                    const photo = new Map(Object.entries(product.photos)).values().next().value as Media
                    return (
                        <>
                        <ListItem key={index} button>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar ${product.name}`}
                                    src={new Map(Object.entries(photo.urls)).get('main') as string}
                                    className = {classes.productImage}
                                />
                            </ListItemAvatar>
                            <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.productName}>
                                {product.name}
                            </Typography>}
                                secondary={<Typography variant="subtitle1" className={classes.productDescription}>
                                    {product.company.name}
                                </Typography>} />
                            <ListItemSecondaryAction>
                                {product.added == false && (
                                    <Checkbox
                                        edge="end"
                                        onChange={(e: any) => { handleToggle(e, product) }}
                                        checked={product.checked}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color="primary"
                                    />
                                )}

                                {product.added && (
                                    <Checkbox
                                        edge="end"
                                        checked={product.added}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color="primary"
                                    />
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider style = {{marginBottom: 12.5,}}/>
                        </>
                    );
                })}
            </List>
            </InfinitScroll>
            <BottomNavigation showLabels className={classes.bottomNavigation}>
                <Button className={classes.nextButton} variant="contained" color="primary" onClick={handleUpdate}>
                    {t("add")} {selectedProductData?.length} {t("products_to_shop")}
                </Button>
            </BottomNavigation>
        </div>
    )
}