import {TextField, Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { AddressAction } from '../../../action/address.action';
import { AddressActor } from '../../../actor/address.actor';
import { District } from '../../../service/response/district.response';
import { AddressStore } from '../../../store/address.store';
import { useObservable } from '../../../useSubscriber';
import {useStyles} from './ui.style';
import { DelarStore } from "../../../store/delar.store"
import { useTranslation } from "react-i18next"

export const DistrictPickerComponent = (props: any) => {

    const [districtList, districtListError] = useObservable(AddressStore.getDistrictList(), AddressStore.getDistrictListObservable())
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())
    const { t } = useTranslation();
    const [districtData, setDistrictData] = useState<Array<District>>([])
    const classes = useStyles();
    useEffect(() => {
        AddressActor({ "type": AddressAction.GET_DISTRICT_LIST }, {"stateCode": props.stateCode})
    }, [])

    useEffect(() => {
        if (!districtListError.error && districtList) {
            var newStates: Array<District> = Object.keys(districtList).map(function (index: any) {
                let district = (districtList as District[])[index]
                return district;
            });
            setDistrictData(newStates)
        }
    }, [districtList])

    return (
        <Autocomplete
            id="combo-box-demo"
            options={districtData}
            getOptionLabel={(option: District) => option.name}
            onChange={(event, district) => props.setDistrict(district)}
            fullWidth={true}
            style={{ marginTop: 8, }}
            loading={districtData.length > 0 ? true : false}
            classes={{ input: classes.input  }} 
            renderInput={(params: any) => <TextField {...params}
            classes = {{
                root: delarDtoError?.district.error?classes.TextFieldRoot: classes.noStyle,
            }}
                label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("choose_district")}</Typography>} variant="outlined" />}
        />
    );
}