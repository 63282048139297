import Axios, { AxiosResponse } from "axios";
import { District } from "./response/district.response";
import { State } from "./response/state.response";
import { SubDistrict } from "./response/sub-district.response";
import { Village } from "./response/village.response";
import { VillageSearchDto } from "./dto/village-search.dto";
import { AddVillageDto } from "./dto/add-village.dto";
export const AddressService = {
    getState: (): Promise<AxiosResponse<Array<State>>> => {
        return Axios.get<Array<State>>(`/state`)
    },
    getDistrictByStateCode: (stateCode: number): Promise<AxiosResponse<Array<District>>> => {
        return Axios.get<Array<District>>(`/state/${stateCode}/district`)
    },
    getSubDistrictByDistrictCode: (districtCode: number): Promise<AxiosResponse<Array<SubDistrict>>> => {
        return Axios.get<Array<SubDistrict>>(`/district/${districtCode}/sub-district`)
    },
    getVillageBySubDistrictCode: (subDistrictCode: number): Promise<AxiosResponse<Array<Village>>> => {
        return Axios.get<Array<Village>>(`/sub-district/${subDistrictCode}/village`)
    },
    getVillageBySearch: (searchDto: VillageSearchDto): Promise<AxiosResponse<Array<Village>>> => {
        return Axios.post<Array<Village>>(`/search-village`, searchDto)
    },
    addVillageDelivery: (addDto: AddVillageDto[]): any => {
        return Axios.post(`/add-delar-delivery-village-list`, addDto)
    }
}