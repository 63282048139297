import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: "20%",
        },
        backIcon: {
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            fontSize: 24,
        },
        title: {
            flexGrow: 1,
            fontSize: 14,
            marginLeft: theme.spacing(2),
        },
        title1: {
            marginTop: 14,
            marginLeft: 16,
            fontSize: 14,
        },
        imageContainer: {
            display: 'inline-block', 
            backgroundColor: '#cfe8fc',
            marginLeft: 16,
            marginTop: 8, 
            height: 79, 
            width: 79, 
            padding: 0,
        },
        icon: {
            width: 36,
            height: 36,
            marginTop: 14,
            marginLeft: 21,
        },
        iconText: {
            alignSelf: "center",
            marginLeft:20,
            height:13,
            width:40,
            fontSize: 10,
        },
        title2: {
            marginTop: 12,
            marginLeft: 16,
        },
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            marginLeft: 16,
            width: '91%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },

        contactStart: {
            width: 70,
            height: '100%',
            background: '#E0E0E0',
        },
        adornedStart: {
            
            height: '100%',
            background: '#E0E0E0',
        },
        input: {
            background: '#FFF',
            marginLeft: 10,
            paddingLeft: 10,
        },
        title3: {
            align: "left",
            letterSpacing: 0,
            marginTop: 12,
            marginLeft: 16,
            display: "flex",
            fontSize: 14,
        },
        numberMargin: {
            width: "95%",
            height: 47,
            marginTop: 8,
            marginLeft: 16,
        },
        textField2: {
            align: "left",
            width: "95%",
            height: 100,
            marginBottom: 28,
            marginLeft: 8,
           
        },
        editIcon: {
            marginLeft : 'auto',
            marginRight: 17,
            marginTop: 12,
            width: 18,
            height: 18,
        },
        textField3: {
            align: "left",
            width: "95%",
            marginTop: 8,
            marginLeft: 16,
        },
        saveButton:{
            width: '91%',
            alignSelf: 'center',
            justifySelf: 'center',
            
            marginLeft: 16,
            marginRight: 16,
            padding: 10,
            textTransform: 'capitalize',
            fontSize: 14,
        },
        bottomNavigation: {
            position: 'fixed',
            marginTop: 73,
            width: '100%',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
    }),
);