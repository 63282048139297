import { AppBar, Avatar, Button, Divider, Checkbox, Container, Slide, Paper, Fab, InputBase, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import SortIcon from '@material-ui/icons/Sort'
import { useStyles } from './product.style'
import { isClassExpression } from "typescript";
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from "react-i18next"
import { useRouteManager } from "../../../router/route.manager";
import { useObservable } from "../../../useSubscriber";
import { ProductStore } from "../../../store/product/product.store";
import { DelarProduct } from "../../../service/response/product.response";
import { ProductActor } from "../../../actor/product/product.store";
import { ProductAction } from "../../../action/product/product.store";
import { Media } from "../../../model/media.model";
import { Motion, spring } from "react-motion";
import Radio from '@material-ui/core/Radio';
import {FilterComponent} from './filter-page/filter.component';
import InfinitScroll from 'react-infinite-scroll-component'
import LoadingComponent from "../../common/loading/loading.component";
//import useInfiniteScroll from 'react-infinite-scroll-hook';
//import ReactPaginate from 'react-paginate';



export let idSelected: string;

export const ProductComponent = () => {

    let delarProudctDisplayList: Array<DelarProduct> = []

    const routeManager = useRouteManager();
    const { t } = useTranslation();
    const classes = useStyles();
    const [checked, setChecked] = React.useState([-1]);
    const [delarProductList, _] = useObservable(ProductStore.getDelarProductList(), ProductStore.getDelarProductListObservable())
    const [delarProductData, setDelarProductData] = useState<Array<DelarProduct>>([])
    const [opacity, setOpacity] = useState(0);
    const [translate, setTranslate] = useState(100);
    const [display, setDisplay] = useState("hidden");
    const timeOut = useRef<any>(0)
    const [openFilter, setFilter] = useState(false);
    const [filterList, setFilterList] = useState([[], [], []]);
    const [isSearchTextChanged, setIsSearchTextChanged] = useState(false);
    const sortByItems = [
        {
            id: 0,
            text: t("default"),
        },
        {
            id: 1,
            text: t("order_a_to_z"),
        },
        {
            id: 2,
            text: t("order_z_to_a"),
        },
        {
            id: 3,
            text: t("new_first"),
        },
        {
            id: 4,
            text: t("old_first"),
        }
      ];
    //const [offset, setOffset] = useState(0);
    //const [perPage] = useState(5);
    //const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        ProductActor({ "type": ProductAction.GET_LIST }, {})
    }, [])

    /*useEffect(() => {
        ProductActor({ "type": ProductAction.GET_LIST }, {})
      }, [offset])*/

    useEffect(() => {
        if(isSearchTextChanged)
        {
            //console.log(delarProductList)
            //setDelarProductData([])
            if (delarProductList.length>0) {
                console.log(delarProductList)
                var newDelarProducts: Array<DelarProduct> = Object.keys(delarProductList).map(function (index: any) {
                    let delarProduct = (delarProductList as DelarProduct[])[index]
                    return delarProduct;
                });
                setDelarProductData([].concat(newDelarProducts))
            } else {
                setDelarProductData([])
            }
        }
        else
        {
            //console.log(delarProductList)
            if (delarProductList) {
                var newDelarProducts: Array<DelarProduct> = Object.keys(delarProductList).map(function (index: any) {
                    let delarProduct = (delarProductList as DelarProduct[])[index]
                    return delarProduct;
                });
                setDelarProductData(delarProductData.concat(newDelarProducts))
            } else {
                setDelarProductData([])
            }
        }
    }, [delarProductList])

    /*const handlePageClick = (e: { selected: any; }) => {
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
    };*/

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleClick = (id: string) => () => {
        idSelected = id;
        ProductActor({"type": ProductAction.OPEN_PRODUCT_INFO},{"delarProductID": id})
        routeManager.openProductDetail(id)
    };

    const handleSearch = (e: any) => {
        setIsSearchTextChanged(true);
        if(e.target.value.length === 0)
        {
            setDelarProductData([]);
            setIsSearchTextChanged(false);
        }
        ProductActor({ "type": ProductAction.UPDATE_SEARCH_TEXT }, { "searchText": e.target.value })
        //ProductActor({ "type": ProductAction.GET_LIST }, {})
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        timeOut.current = setTimeout(() => {
            ProductActor({ "type": ProductAction.GET_LIST }, {})
        }, 500);
    }
    const closeFilter = () => {
        setFilter(false);
    }
    const filterHandle = () => {
        setFilter(true);
        
    }
    const animate = () => {
        setOpacity((opacity === 0.5)? 0: 0.5);
        setTranslate((translate === 0)? 100: 0);
        setDisplay((display === "visible")? "hidden": "visible");
    };
    const handleFilteredList = (list: any) => {
        ProductActor({ "type": ProductAction.UPDATE_COMPANY_ID }, { "companyIds": list[2] })
        ProductActor({ "type": ProductAction.UPDATE_AVAILABILITY }, { "avability": 1 })
        ProductActor({ "type": ProductAction.GET_LIST }, {})
    };

    const fetchNextProducts = () => {
        setIsSearchTextChanged(false);
        ProductActor({ "type": ProductAction.GET_LIST }, {})
    };
    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={t("search")}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <FilterComponent open ={openFilter} handleClose = {closeFilter} handleFilter = {(value : any) => {handleFilteredList(value)}}/>
            <div className = {classes.topMenu}>
                <Button color='inherit' className = {classes.buttonStyle} onClick = {animate}>
                    <SortIcon className = {classes.topIcon} />
                    {t("sort_by")}
                </Button>
                <div className = {classes.separator} />
                <Button color='inherit' className = {classes.buttonStyle} onClick = {filterHandle}>
                <i className="fas fa-filter" style = {{fontSize: 15, marginRight:13,}}></i>
                    {t("filter_by")}
                </Button>                        
            </div>


            <InfinitScroll
                dataLength = {delarProductData.length}
                next = {fetchNextProducts}
                hasMore = {true}
                loader={<LoadingComponent/>}
            >
            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 50 }}>

                {delarProductData.map((step,index) => {
                    const labelId = step.id;
                    let photo = null;
                    if(step.product.photos)
                    photo = new Map(Object.entries(step.product.photos)).values().next().value as Media
                    return (
                        <>
                        <ListItem key={index} button
                        onClick={handleClick(step.id)}>
                        <ListItemAvatar >
                            <Avatar
                                alt={`Avatar ${step.id}`}
                                src={photo !== null ? new Map(Object.entries(photo.urls)).get('main') as string: ""}
                                style = {{width: 60, height: 60,}}
                            />
                        </ListItemAvatar>
                        <ListItemText disableTypography id={labelId} primary={<Typography variant = "body2" className = {classes.productName}>
                            {step.product.name}
                            </Typography>} 
                            secondary={<Typography variant = "subtitle1" className = {classes.productDescription}>
                            {step.product.company}
                            </Typography>} />
                        <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                onChange={handleToggle(index)}
                                checked={checked.indexOf(index) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                                color= "primary"
                            />
                        </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        </>
                    );
                })}
            </List>
            </InfinitScroll>
            <Fab variant="extended" color="primary" className={classes.floatingIcon}
            onClick={() => {routeManager.openAddProduct()}}>
                {t("add_product")}
            </Fab>
            {/* Sort By Code */}
            <div>
                <Motion
                style={{
                    opacity: spring(opacity),
                    translate: spring(translate)
                }}
                >
                {({ opacity, translate }) => (
                    <div className = {classes.container}
                    style={{
                        visibility: (display === "visible"?"visible": "hidden"),
                    }}
                    onClick = {() => {console.log("div called");animate()}}
                    >
                    <div className = {classes.backgroundContainer}
                        style={{
                        opacity: opacity
                        }}
                    />
                    <List dense className = {classes.list} style = {{transform: `translateY(${translate}%)`}}>
                        <ListItem>
                            <ListItemText disableTypography  
                            primary={<Typography 
                                        variant = "body2" 
                                        className = {classes.productName}
                                        style = {{fontSize: 14, opacity: 0.5,}}
                                        >
                                {t("sort_by")}
                                </Typography>} />
                        </ListItem>
                    {sortByItems.map((step,index) => {
                        return (
                            <>
                            <ListItem key={index} button
                            onClick={animate}>
                            
                            <ListItemText disableTypography  primary={<Typography variant = "body2" className = {classes.productName}>
                                {step.text}
                                </Typography>} 
                                />
                            <ListItemSecondaryAction>
                                <Radio
                                    edge="end"
                                    onChange={handleToggle(index)}
                                    checked={checked.indexOf(index) !== -1}
                                    size="small"
                                    color= "primary"
                                />
                            </ListItemSecondaryAction>
                            
                            </ListItem>
                            <Divider />
                            </>
                            
                        );
                    })}
                </List>
                </div>
             )}
            </Motion>
      </div>
        </React.Fragment>
    )
}