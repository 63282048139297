import { Container, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useStyles } from "./validate-otp.style";
import OtpInput from 'react-otp-input';
import { useObservable } from "../../../useSubscriber";
import { RegistrationStore } from "../../../store/registration.store";
import { useTranslation } from "react-i18next";
import { RegistrationAction } from "../../../action/registration.action";
import { RegistrationActor } from "../../../actor/registration.actor";
import { useRouteManager } from "../../../router/route.manager";
import { GenerateOTPRes, ValidateOTPRes } from "../../../service/response/registration.response";
import { DelarStatus } from "../../../enum/delar-status.enum";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from "react-router-dom";


export const ValidateOtpComponent = () => {
    const { t } = useTranslation();
    const routeManager = useRouteManager();

    const [validateOtpRes, validateOtpError] = useObservable(RegistrationStore.getValidateOtpRes(), RegistrationStore.getValidateOtpResObservable())

    const genearteOtpRes = RegistrationStore.getGenearteOtpRes() as GenerateOTPRes

    const classes = useStyles();

    const [state, setState] = useState({
        otp: '',
    });

    const [seconds, setSeconds] = useState(30);

    const handleChange = (otp: any) => {
        setState({ otp });
        if(otp.length >= 4) {
            validateOTP(otp)
        }
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
        }
    });

    useEffect(() => {
        if (!genearteOtpRes) {
            routeManager.openRegister()
        }
    }, [])

    useEffect(() => {
        if (!validateOtpError.error && validateOtpRes) {
            var _validateOtpRes = validateOtpRes as ValidateOTPRes
            if (_validateOtpRes.delar.status.value == DelarStatus.NotInit) {
                if(genearteOtpRes.exist) {
                    routeManager.openDelarDetail()
                } else {
                    routeManager.openIntro()
                }
            } else {
                routeManager.openHome()
            }
        }
    }, [validateOtpRes, validateOtpError])

    const validateOTP = (otp: string) => {
        if (genearteOtpRes) {
            RegistrationActor({ "type": RegistrationAction.VALIDATE_OTP }, {
                "otp": otp,
                "phone": genearteOtpRes.phone
            })
        }
    }
    const navigate = useNavigate()
    return (
        <div className={classes.root}>
                <ArrowBackIcon  className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                <div className = {classes.otpHolder}>
                <Typography variant="h4" className={classes.title1} >
                    <b>{t("otp")}</b>
                </Typography>
                <Typography variant="subtitle2" className={classes.title2} >
                    {t("validate_otp_input")} +91 {genearteOtpRes.phone}
                </Typography>
                <OtpInput
                    value={state.otp}
                    onChange={handleChange}
                    className={classes.otpFormat}
                    numInputs={4}
                    inputStyle = {classes.inputStyle}
                    separator={<span></span>}
                />
                <div style={{ display: "flex" }}>
                    <Typography variant="subtitle2" className={classes.title3} noWrap>
                        {t("validate_otp_didnt_recieve")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.title4} noWrap>
                        00:{seconds} {t("validate_otp_resend")}
                    </Typography>
                </div>
                </div>
                
            </div>
    )
}
