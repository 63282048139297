import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            
            flexGrow: 1,
            paddingBottom:10,
            
        },
        backIcon: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            fontSize: 30,
        },
        title1: {
            flexGrow: 1,
            align: "left",
            letterSpacing: 0,
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(25),
            fontSize: 24,
            fontWeight: 'bold',
        },
        title2: {
            flexGrow: 1,
            align: "left",
            opacity: 0.75,
            letterSpacing: 0.24,
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            fontSize: 12,
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        numberMargin: {
            width: '90%',
            height: 47,
            margin: theme.spacing(2),
        },
        nextButton: {
            position: 'fixed',
            bottom: 13,
            left: 16,
            margin: '0 auto',
            align: "center",
            width: '90%',
            height: 48,
            borderRadius: 4,
            textTransform: 'capitalize',
        },
        adornedStart: {
            
            height: '100%',
            background: '#E0E0E0',
        },
        input: {
            background: '#FFF',
            marginLeft: 10,
            paddingLeft: 10,
        },
    }),
);