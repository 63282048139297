import { Avatar, Button, Card, CardContent, CardHeader, Collapse, Container, IconButton, TextField, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useStyles } from "./document-proof.style"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { DelarStore } from "../../../../store/delar.store"
import { DelarActor } from "../../../../actor/delar.actor";
import { DelarAction } from "../../../../action/delar.action";
import { DtoError } from "../../../../model/dto-error.model"
import { BehaviorSubject, Observable } from "rxjs"
import { SecurityManager } from "../../../../security/security.manager";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component"
import { useRouteManager } from "../../../../router/route.manager"
import { ExpandLess, PinDropSharp } from "@material-ui/icons"
import { useObservable } from "../../../../useSubscriber"
import { useTranslation } from "react-i18next"

function clsx(expand: any, arg1: { [x: number]: any }): string | undefined {
    throw new Error("Function not implemented.")
}

export const DocumetProofComponent = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const routeManager = useRouteManager();
    const [expanded1, setExpanded1] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const [expanded3, setExpanded3] = React.useState(false);
    const [value1, setValue1] = useState("")
    const [value2, setValue2] = useState("")
    const [value3, setValue3] = useState("")
    const [pesticideImages, setpesticideImages] = useState([]);
    const [fertiliserImages, setfertiliserImages] = useState([]);
    const [seedImages, setseedImages] = useState([]);
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())



    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };
    const handleExpandClick3 = () => {
        setExpanded3(!expanded3);
    };

    const setPesticideLicenseNumber = (data: string) => {
        DelarStore.updatePesticideLicenseNumber(data)
    };
    const setFertilizerLicenseNumber = (data: string) => {
        DelarStore.updateFertilizerLicenseNumber(data)
    };
    const setSeedLicenseNumber = (data: string) => {
        DelarStore.updateSeedLicenseNumber(data)
    };

    const handleChangePesticideLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setValue1(e.target.value)
        setPesticideLicenseNumber(value1)
    };
    const handleChangeFertilizerLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setValue2(e.target.value)
        setFertilizerLicenseNumber(value2)
    };
    const handleChangeSeedLicense = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setValue3(e.target.value)
        setSeedLicenseNumber(value3)
    };

    const handlepesticideImages = (value : any) => {
        setpesticideImages(value);
    };
    const handlefertiliserImages = (value : any) => {
        setfertiliserImages(value);
    };
    const handleseedImages = (value : any) => {
        setseedImages(value);
        
    };
    const afterUpload1 = () => {
        props.setLoading(false);
            setExpanded1(false);
    }
    const afterUpload2 = () => {
        props.setLoading(false);
            setExpanded2(false);
    }
    const afterUpload3 = () => {
        props.setLoading(false);
            setExpanded3(false);
    }
    const handleUpload1 = () => {
        if((delarDtoError.pesticideLiscence.error  ))
        return;
        props.setLoading(true); 
        DelarActor({"type": DelarAction.UPLOAD_PESTICIDE_IMAGES},{"delarId": SecurityManager.getUid(), "onUploaded": afterUpload1, "photos": pesticideImages, "action": ()=>{}, "errorAction": ()=>{}})  
    };
    const handleUpload2 = () => {
        if((delarDtoError.fertilizerLiscence.error ))
        return;
        props.setLoading(true);
        DelarActor({"type": DelarAction.UPLOAD_FERTILISER_IMAGES},{"delarId": SecurityManager.getUid(), "onUploaded": afterUpload2, "photos": fertiliserImages, "action": ()=>{}, "errorAction": ()=>{}})
    };
    const handleUpload3 = () => {
        if((delarDtoError.seedLiscence.error  ))
        return;
        props.setLoading(true);
        
        DelarActor({"type": DelarAction.UPLOAD_SEED_IMAGES},{"delarId": SecurityManager.getUid(), "onUploaded": afterUpload3, "photos": seedImages, "action": ()=>{}, "errorAction": ()=>{}})
    };
    const [error, setError] = useState(true);
    return (
    <div className={classes.root}>
        <Typography variant="h4" className={classes.heading} >
                <b>{t("onboarding_document_details_proof")}</b>
            </Typography>
        <Card className={classes.subRoot} >
            <CardHeader onClick={handleExpandClick1}
                title={<Typography variant="body2" style ={{fontSize: 14, fontWeight: 'bold'}}>{t("pesticide_license")}</Typography>}
                action = {
                    <IconButton
                        className={classes.expand}
                        
                        aria-expanded={expanded1}
                        aria-label="show more"
                    >
                        {expanded1? <ExpandLess />:<ExpandMoreIcon />}
                    </IconButton>
                }
            />
            <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("onboarding_document_details_license_number")}</b>
                    </Typography>
                    <form className={classes.formField} noValidate autoComplete="off">
                        <TextField 
                        id="outlined-basic" 
                        value={value1} 
                        className={classes.textField1} 
                        label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("onboarding_document_details_type_pesticide")}</Typography>} 
                        variant="outlined" 
                        classes = {{
                            root: delarDtoError?.pesticideLiscence.error?classes.TextFieldRoot: classes.noStyle,
                        }}
                        onChange={handleChangePesticideLicense} />
                        {delarDtoError?.pesticideLiscence.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.pesticideLiscence?.msg}
                        </Typography> }
                    </form> 
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("upload_license")}</b>
                    </Typography>
                    <div style={{display:"flex"}}>
                            <GalleryPickerComponent handleUpload = { handlepesticideImages }/>
                    </div>
                    {delarDtoError?.pesticidePhoto.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.pesticidePhoto?.msg}
                        </Typography>}
                    <Button variant="outlined" color="primary" className = {classes.uploadButton} onClick = {() => {
                        handleUpload1()
                        }
                    }>
                        {t("onboarding_document_details_upload_details")}
                    </Button>
                </CardContent>
            </Collapse>
        </Card>
        <Card className={classes.subRoot} >
            <CardHeader onClick={handleExpandClick2}
                title={<Typography variant="body2" style ={{fontSize: 14, fontWeight: 'bold'}}>{t("fertiliser_license")}</Typography>}
                action = {
                    <IconButton
                        className={classes.expand}
                        
                        aria-expanded={expanded2}
                        aria-label="show more"
                    >
                        {expanded2? <ExpandLess />:<ExpandMoreIcon />}
                    </IconButton>
                }
            />
            <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("onboarding_document_details_fertiliser_number")}</b>
                    </Typography>
                    <form className={classes.formField} noValidate autoComplete="off">
                        <TextField 
                        id="outlined-basic" 
                        value={value2} 
                        className={classes.textField1} 
                        label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("onboarding_document_details_type_fertiliser")}</Typography>} 
                        variant="outlined" 
                        classes = {{
                            root: delarDtoError?.fertilizerLiscence.error?classes.TextFieldRoot: classes.noStyle,
                        }}
                        onChange={handleChangeFertilizerLicense}/>
                        {delarDtoError?.fertilizerLiscence.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.fertilizerLiscence.msg}
                        </Typography> }
                    </form> 
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("upload_license")}</b>
                    </Typography>
                    <div style={{display:"flex"}}>
                            <GalleryPickerComponent handleUpload = { handlefertiliserImages }/>
                    </div>
                    {delarDtoError?.fertilizerPhoto.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.fertilizerPhoto?.msg}
                        </Typography>}
                    <Button variant="outlined" color="primary" className = {classes.uploadButton} onClick = {() => 
                            {
                                handleUpload2();
                            }
                        }>
                        {t("onboarding_document_details_upload_details")}
                    </Button>
                </CardContent>
            </Collapse>
        </Card>
        <Card className={classes.subRoot} style = {{marginBottom: 90,}} >
            <CardHeader onClick={handleExpandClick3}
                title={<Typography variant="body2" style ={{fontSize: 14, fontWeight: 'bold'}}>{t("seed_license")}</Typography>}
                action = {
                    <IconButton
                        className={classes.expand}
                        
                        aria-expanded={expanded3}
                        aria-label="show more"
                    >
                        {expanded3? <ExpandLess />:<ExpandMoreIcon />}
                    </IconButton>
                }
            />
            <Collapse in={expanded3} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("onboarding_document_details_seed_number")}</b>
                    </Typography>
                    <form className={classes.formField} noValidate autoComplete="off">
                        <TextField 
                        id="outlined-basic" 
                        
                        value={value3} 
                        className={classes.textField1} 
                        label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("onboarding_document_details_type_seed")}</Typography>} 
                        variant="outlined" 
                        classes = {{
                            root: delarDtoError?.seedLiscence.error?classes.TextFieldRoot: classes.noStyle,
                        }}
                        onChange={handleChangeSeedLicense}/>
                        {delarDtoError?.seedLiscence.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.seedLiscence.msg}
                        </Typography> }
                    </form> 
                    <Typography variant="body1" className={ classes.title1 } >
                        <b>{t("upload_license")}</b>
                    </Typography>
                    <div style={{display:"flex"}}>
                            <GalleryPickerComponent handleUpload = { handleseedImages }/>
                    </div>
                    {delarDtoError?.seedPhoto.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.seedPhoto?.msg}
                        </Typography>}
                    <Button variant="outlined" color="primary" className = {classes.uploadButton} onClick = {() => {
                        handleUpload3();
                        }
                    }>
                        {t("onboarding_document_details_upload_details")}
                    </Button>
                </CardContent>
            </Collapse>
        </Card>
    </div>
  );
}
