import { AppBar, Avatar, Container, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './setting.style'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

export const SettingComponent = () => {
    const navigate = useNavigate()
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <React.Fragment>
           <AppBar position="static">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <Typography variant="h6" className={classes.title}>
                        {t("settings")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <List className={classes.root}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'notificationIcon'} src={'../asset/Notification.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText  primary={<Typography variant="body2" className = {classes.title1}>{t("notifications")}</Typography>} />
                    <ListItemSecondaryAction>
                            <Switch
                                edge="end"
                                //onChange={handleToggle(index)}
                                //checked={checked.indexOf(index) !== -1}
                                color= "primary"
                            />
                        </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'languageIcon'} src={'../asset/Language.svg'} className={classes.icon}/> 
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body1" className = {classes.title1}>{t("language")}</Typography>} secondary={<Typography variant="body1" className = {classes.title1}>English</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'aboutIcon'} src={'../asset/About.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("about_khetipoint")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'supportIcon'} src={'../asset/Support.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary = {<Typography variant="body2" className = {classes.title1}>{t("support")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'termsIcon'} src={'../asset/Terms and Policy.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("terms_policy")}</Typography>} />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar alt={'logoutIcon'} src={'../asset/Logout.svg'} className={classes.icon}/>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="body2" className = {classes.title1}>{t("logout")}</Typography>} />
                </ListItem>
            </List>
        </React.Fragment>
    )
}