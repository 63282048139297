import { AppBar, Avatar, BottomNavigation, Button, Container, Divider, TextField, Toolbar, Typography,} from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from './edit-license.style'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { EditProfileActor } from "../../../../../actor/edit-profile.actor"
import { EditProfileAction } from "../../../../../action/edit-profile.action"
import { useObservable } from "../../../../../useSubscriber"
import { EditProfileStore } from "../../../../../store/edit-profile.store"
import { SecurityManager } from "../../../../../security/security.manager"
import { GalleryPickerComponent } from "../../../../Image_Picker/gallery-picker.component"
import { useTranslation } from "react-i18next"

export const EditLicenseComponent = () => {

    const classes = useStyles();
    const [error, setError] = useState(true);
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const { t } = useTranslation();
    const handleChangePesticideLicense = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_PESTICIDE_LICENSE}, {"pesticideLicenseNumber": e.target.value})
    };

    const handleUpdate = () => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid() })
    };
    
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("change_license_number")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className = {classes.imgContainer}>
                <img alt={'editLicenseIllustration'} src={'/asset/Edit License Illustration.svg'} />
            </div>
            
            <Typography variant="subtitle1" className={classes.title1} align={'center'}>
                {t("change_requirement_license_card_no")}
            </Typography>
            <Typography variant="body1" className={ classes.title2 } >
                <b>{t("pesticide_license")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.pesticideLiscence?.newLicence?.number} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your License Number</Typography>} variant="outlined" onChange={handleChangePesticideLicense} />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </form> 
            <Typography variant="body1" className={ classes.title2 } >
                <b>{t("upload_license")}</b>
            </Typography>
            
            <GalleryPickerComponent/>
            
            <BottomNavigation
                showLabels
                className={classes.bottomNavigation}
            >
                <Button variant="contained" color="primary" className={classes.requestButton} onClick={handleUpdate}>
                    {t("send_request")}
                </Button>
            </BottomNavigation>
        </div>
    )
}