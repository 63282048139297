import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        kpLogo: {
            width: 75,
            height: 75,
            marginTop: 157,
        },
        langTitle1: {
            flexGrow: 1,
            align: "center",
            marginTop: 54,
            font: "Montserrat",
            fontSize: 16,
        },
        langTitle2: {
            flexGrow: 1,
            align: "center",
            marginTop: 8,
            fontSize: 16,
        },
        engButton: {
            marginTop: 24,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: 16,
            fontWeight: 600,
        },
        hinButton: {
            marginTop: 20,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: 16,
            fontWeight: 600,
        },
        gujButton: {
            marginTop: 20,
            textAlign: "center",
            width: 240,
            height: 48,
            fontSize: 16,
            fontWeight: 600,
        },
    }),
);