import { BehaviorSubject, Observable } from "rxjs"
import { Address, GeoAddress } from "../model/address.model"
import { DelarLicence, Licence, ShopName } from "../model/delar.model"
import { DtoError } from "../model/dto-error.model"
import { DelarDto, DelarDtoError } from "../service/dto/delar.dto"
import { District } from "../service/response/district.response"
import { State } from "../service/response/state.response"
import { SubDistrict } from "../service/response/sub-district.response"
import { Village } from "../service/response/village.response"


let _delarDto: DelarDto = {shopName:{} as ShopName,
                           shopAddress:{geoAddress:{} as GeoAddress} as Address,
                           fertilizerLiscence:{newLicence:{} as Licence} as DelarLicence, 
                           pesticideLiscence: {newLicence:{} as Licence} as DelarLicence, 
                           seedLiscence: {newLicence:{} as Licence} as DelarLicence} as DelarDto

let _delarDtoError: DelarDtoError = {
    phone: new DtoError("", false),
    name: new DtoError("", false),
    language: new DtoError("", false),
    intro: new DtoError("", false),
    alternatePhone: new DtoError("", false),
    shopName: new DtoError("", false),
    shopAddress: new DtoError("", false),
    shopPhotos: new DtoError("", false),
    pesticideLiscence: new DtoError("", false),
    fertilizerLiscence: new DtoError("", false),
    seedLiscence: new DtoError("", false),
    status: new DtoError("", false),
    location: new DtoError("", false),
    active: new DtoError("", false)
} as DelarDtoError

let _delarDtoSubject = new BehaviorSubject<DelarDto | null>(null)
let _delarDtoErrorSubject = new BehaviorSubject<DelarDtoError | null>(null)

export const EditProfileStore = {
    getDelarDto: (): DelarDto | null => {
        return _delarDto
    },
    getDelarDtoError: (): DelarDtoError | null => {
        return _delarDtoError
    },
    setDelarDto: (newDelarDto: DelarDto) => {
        _delarDto = newDelarDto
        _delarDtoSubject.next({ ..._delarDto })
    },
    updatePhone: (phone: string) => {
        //console.log(phone)
        _delarDto.phone = phone
        if(phone?.length> 10 || phone?.length < 10) {
            _delarDtoError.phone.error = true
            _delarDtoError.phone.msg = "Phone should contain 10 chars"
        } else {
            _delarDtoError.phone.error = false
            _delarDtoError.phone.msg = ""
        }
        _delarDtoErrorSubject.next({..._delarDtoError})
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateName: (name: string) => {
        //console.log(name)
        _delarDto.name = name
        if(name?.length < 3 || name?.length > 25) {
            _delarDtoError.name.error = true
            _delarDtoError.name.msg = "Name should contain  3to 25 chars"
        } else {
            _delarDtoError.name.error = false
            _delarDtoError.name.msg = ""
        }
        _delarDtoErrorSubject.next({..._delarDtoError})
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateIntro: (intro: string) => {
        _delarDto.intro = intro
        console.log(intro)
        if(intro?.length < 4 || intro?.length > 80) {
            _delarDtoError.intro.error = true
            _delarDtoError.intro.msg = "Intro should contain  4 to 80 chars"
        } else {
            _delarDtoError.intro.error = false
            _delarDtoError.intro.msg = ""
        }
        _delarDtoErrorSubject.next({..._delarDtoError})
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateShopName: (shopName: string) => {
        //console.log(shopName)
        _delarDto.shopName.newName = shopName
        _delarDtoSubject.next({..._delarDto})
    },
    updatePesticideLicenseNumber: (pesticideLicenseNumber: string) => {
        //console.log(pesticideLicenseNumber)
        _delarDto.pesticideLiscence.newLicence.number = pesticideLicenseNumber
        _delarDtoSubject.next({..._delarDto})
    },
    updateFertilizerLicenseNumber: (fertilizerLicenseNumber: string) => {
        _delarDto.fertilizerLiscence.newLicence.number = fertilizerLicenseNumber
        _delarDtoSubject.next({..._delarDto})
    },
    updateSeedLicenseNumber: (seedLicenseNumber: string) => {
        _delarDto.seedLiscence.newLicence.number = seedLicenseNumber
        _delarDtoSubject.next({..._delarDto})
    },
    updateStreetAddress: (streetLine: string) => {
        console.log(streetLine)
        _delarDto.shopAddress.streetLine = streetLine
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateLandmark: (landMark: string) => {
        console.log(landMark)
        _delarDto.shopAddress.landMark = landMark
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateState: (state: State) => {
        _delarDto.shopAddress.geoAddress.state = state
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateDistrict: (district: District) => {
        _delarDto.shopAddress.geoAddress.district = district
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateSubDistrict: (subDistrict: SubDistrict) => {
        _delarDto.shopAddress.geoAddress.subDistrict = subDistrict
        _delarDtoSubject.next({ ..._delarDto })
    },
    updateVillage: (village: Village) => {
        _delarDto.shopAddress.geoAddress.village = village
        _delarDtoSubject.next({ ..._delarDto })
    },
    updatePincode: (pincode: string) => {
        console.log(pincode)
        _delarDto.shopAddress.pinCode = Number(pincode)
        _delarDtoSubject.next({..._delarDto})
    },
    getDelarDtoObservable: (): Observable<DelarDto | null> => {
        return _delarDtoSubject.asObservable()
    },
    getDelarDtoErrorObservable: (): Observable<DelarDtoError | null> => {
        return _delarDtoErrorSubject.asObservable()
    },
}


