import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    iconContainer: {
      transform: 'scale(1.5)',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    nextButton: {
        width: "80%",
        margin: "auto",
        display: "flex",
        textTransform: 'capitalize',
    },
    nextButtonCard: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        padding: "12px 0px 12px 0px",
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    }
  }),
);