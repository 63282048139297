import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title1: {
            align: "left",
            letterSpacing: 0,
            marginLeft: 0,
            display: "flex",
            fontSize: 24,
            fontWeight: 'bold',
        },
        title2: {
            align: "left",
            fontSize: 12,
        },
        formField: {
            align: "center",
            marginTop: 8,
            fontSize: 12,
            marginBottom: 16,
            width: '98%',
            
        },
        textField1: {
            align: "left",
            width: "100%",
            height: '100%',
        },
        
        adornedStart: {
            height: '100%',
            background: '#E0E0E0',
        },
        input: {
            background: '#FFF',
            marginLeft: 10,
            paddingLeft: 10,
        },
        errorText: {
            marginTop: 6,
            color: 'red',
            
            fontSize: 11,
        },
        errorIcon: {
            verticalAlign: 'middle',
            fontSize: 14,
            marginRight: 4,
        },
        TextFieldRoot: {
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'red',
                },
            },
        },
        noStyle: {},
        
    }),
);