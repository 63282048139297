import { AppBar, Avatar, BottomNavigation, Button, Card, CardContent, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Toolbar, Typography } from "@material-ui/core"
import React, {useState} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from "./edit-profile.style"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useObservable } from "../../../../useSubscriber";
import { EditProfileStore } from "../../../../store/edit-profile.store";
import { EditProfileActor } from "../../../../actor/edit-profile.actor";
import { EditProfileAction } from "../../../../action/edit-profile.action";
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component";
import { SecurityManager } from "../../../../security/security.manager";
import { useTranslation } from "react-i18next"

export const EditProfileComponent = () => {
    const [error, setError] = useState(true);
    const [delarDto, _] = useObservable(EditProfileStore.getDelarDto(), EditProfileStore.getDelarDtoObservable())
    const [delarDtoError, dtoee] = useObservable(EditProfileStore.getDelarDtoError(), EditProfileStore.getDelarDtoErrorObservable())
    const classes = useStyles();
    const { t } = useTranslation();


    const handleChangeName = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_NAME}, {"name": e.target.value})
    };

    const handleChangePhone = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_PHONE}, {"phone": e.target.value})
    };

    const handleChangeIntro = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_INTRO}, {"intro": e.target.value})
    };

    const handleChangeShopName = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_SHOP_NAME}, {"newShopName": e.target.value})
    };

    const handleChangePesticideLicense = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_PESTICIDE_LICENSE}, {"pesticideLicenseNumber": e.target.value})
    };

    const handleChangeFertilizerLicense = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_FERTILIZER_LICENSE}, {"pesticideLicenseNumber": e.target.value})
    };

    const handleChangeSeedLicense = (e: any) => {
        EditProfileActor({"type": EditProfileAction.UPDATE_SEED_LICENSE}, {"pesticideLicenseNumber": e.target.value})
    };

    const handleUpdate = () => {
        EditProfileActor({ "type": EditProfileAction.UPDATE_DATA }, { "delarId": SecurityManager.getUid() })
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {t("edit_profile")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Typography variant="subtitle1" className={classes.title1} >
                <b> {t("edit_shop_picture")} </b>
            </Typography>
            <GalleryPickerComponent/>
            <Typography variant="subtitle1" className={classes.title1} >
                <b> {t("edit_name")} </b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField id="outlined-basic" value={delarDto?.name} className={classes.textField1} label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">Type your Name</Typography>} variant="outlined" onChange = {handleChangeName}/>

                    {delarDtoError?.name.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.name?.msg}
                    </Typography> }
            </form>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("edit_contact_number")}</b>
            </Typography>
            <FormControl  className={classes.formField}variant="outlined">
                    <OutlinedInput
                        //id="input-phone-number"
                        //value={values.phoneNumber}
                        //onChange={handleChange('phoneNumber')}
                        value = {delarDto?.phone}
                        classes = {{
                            adornedStart: classes.adornedStart,
                            input: classes.input,
                        }}
                       startAdornment={<InputAdornment position="start" >+91</InputAdornment>}
                       onChange = {handleChangePhone}
                    />
                    {delarDtoError?.phone.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.phone?.msg}
                        </Typography> }
            </FormControl>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("edit_intro")}</b>
            </Typography>
            <form className={classes.formField} noValidate autoComplete="off">
                <TextField
                    value = {delarDto?.intro}
                    className={classes.textField1}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder = "Default Value"
                    variant = "outlined"
                    onChange = {handleChangeIntro}
                />  
                {delarDtoError?.intro.error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{delarDtoError?.intro?.msg}
                        </Typography> }
            </form>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("shop_name")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="shopName" style = {{fontSize: 12,}}>Type your shop name</InputLabel>
                <OutlinedInput
                    id="shopName"
                    value={delarDto?.shopName?.newName}
                    onChange={handleChangeShopName}
                    endAdornment={
                    <InputAdornment position="end">
                        <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
                    </InputAdornment>
                }
                labelWidth={170}
                />
                {/*error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>This is a required field
                        </Typography> */}
            </FormControl>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("address")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="shopName" style = {{fontSize:12,}}>{t("type_address")}</InputLabel>
                <OutlinedInput
                    id="talukaName"
                    //value={values.stateName}
                    //onChange={handleChange('stateName')}
                    endAdornment={
                    <InputAdornment position="end">
                        <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
                    </InputAdornment>
                }
                labelWidth={170}
                />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </FormControl>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("pesticide_license")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="shopName" style = {{fontSize:12,}}>{t("type_license_number")}</InputLabel>
                <OutlinedInput
                    id="talukaName"
                    value={delarDto?.pesticideLiscence?.newLicence?.number}
                    onChange={handleChangePesticideLicense}
                    endAdornment={
                    <InputAdornment position="end">
                        <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
                    </InputAdornment>
                }
                labelWidth={170}
                />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </FormControl>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("fertiliser_license")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="shopName" style = {{fontSize:12,}}>{t("type_license_number")}</InputLabel>
                <OutlinedInput
                    id="fertilizerLicense"
                    value={delarDto?.fertilizerLiscence?.newLicence?.number}
                    onChange={handleChangeFertilizerLicense}
                    endAdornment={
                    <InputAdornment position="end">
                        <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
                    </InputAdornment>
                }
                labelWidth={170}
                />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </FormControl>
            <Typography variant="subtitle1" className={ classes.title3 } >
                <b>{t("seed_license")}</b>
            </Typography>
            <FormControl className={classes.formField} variant="outlined">
                <InputLabel htmlFor="shopName" style = {{fontSize:12,}}>{t("type_license_number")}</InputLabel>
                <OutlinedInput
                    id="seedLicense"
                    value={delarDto?.seedLiscence?.newLicence?.number}
                    onChange={handleChangeSeedLicense}
                    endAdornment={
                    <InputAdornment position="end">
                        <Avatar alt="editIcon" src={'../asset/Edit Icon.svg'} aria-label="show more" className={classes.editIcon} />
                    </InputAdornment>
                }
                labelWidth={170}
                />
                {error && <Typography variant="body1" className={ classes.errorText }>
                        <ErrorOutlineIcon className = {classes.errorIcon}/>{t("required_field")}
                    </Typography> }
            </FormControl>
            <BottomNavigation className = {classes.bottomNavigation}>
                <Button variant="contained" color="primary" className={classes.saveButton} onClick={handleUpdate}>
                        {t("save")}
                    </Button>
            </BottomNavigation>
        </div>
    )
}