import { TextField, Typography } from "@material-ui/core"
import { useStyles } from "./business-detail.style"
import { DistrictPickerComponent } from "../../../common/address-picker/district-picker.component"
import { District } from "../../../../service/response/district.response"
import { SubDistrict } from "../../../../service/response/sub-district.response"
import { Village } from "../../../../service/response/village.response"
import { State } from "../../../../service/response/state.response"
import { DelarStore } from "../../../../store/delar.store"
import { useObservable } from "../../../../useSubscriber"
import React from "react"
import { DelarActor } from "../../../../actor/delar.actor"
import { DelarAction } from "../../../../action/delar.action"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { GalleryPickerComponent } from "../../../Image_Picker/gallery-picker.component"
import { StatePickerComponent } from "../../../common/address-picker/state-picker.component"
import { SubDistrictPickerComponent } from "../../../common/address-picker/sub-district-picker.component"
import { VillagePickerComponent } from "../../../common/address-picker/village-picker.component"
import { useTranslation } from "react-i18next"

export const BusinessDetailComponent = (props: any) => {

    const classes = useStyles();

    const { t } = useTranslation();
    const [delarDto] = useObservable(DelarStore.getDelarDto(), DelarStore.getDelarDtoObservable())
    const [delarDtoError] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())

    const setState = (state: State) => {
        DelarActor({ "type": DelarAction.UPDATE_STATE }, { "state": state })
    }

    const setDistrict = (district: District) => {
        DelarActor({ "type": DelarAction.UPDATE_DISTRICT }, { "district": district })
    }

    const setSubDistrict = (subDistrict: SubDistrict) => {
        DelarActor({ "type": DelarAction.UPDATE_SUB_DISTRICT }, { "subDistrict": subDistrict })
    }

    const setVillage = (village: Village) => {
        DelarActor({ "type": DelarAction.UPDATE_VILLAGE }, { "village": village })
    }

    const handleChangeShopName = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_SHOP_NAME }, { "shopName": e.target.value })
    };

    const handleChangeStreetAddress = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_STREET_ADDRESS }, { "streetLine": e.target.value })
    };

    const handleChangeLandMark = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_LANDMARK }, { "landMark": e.target.value })
    };

    const handleChangePinCode = (e: any) => {
        DelarActor({ "type": DelarAction.UPDATE_PIN_CODE }, { "pinCode": e.target.value })
    };

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.title1} >
                <b>{t("onboarding_business_details")}</b>
            </Typography>
            <Typography variant="body1" className={classes.title2} >
                <b>{t("onboarding_business_upload_shop")}</b>
            </Typography>

            <GalleryPickerComponent handleUpload={props.handleUpload} />
            
            {delarDtoError?.shopPhotos.error && <Typography variant="body1" className={classes.errorText}>
                <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.shopPhotos?.msg}
            </Typography>}
            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("shop_name")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopName.newName}
                        className={classes.textField1}
                        classes={{
                            root: delarDtoError?.shopName.error ? classes.TextFieldRoot : classes.noStyle,
                        }}
                        label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("type_shop_name")}</Typography>}
                        variant="outlined"
                        onChange={handleChangeShopName} />
                    {delarDtoError?.shopName.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.shopName?.msg}
                    </Typography>}
                </form>
            </React.Fragment>


            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("street_address")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopAddress.streetLine}
                        className={classes.textField1}
                        classes={{
                            root: delarDtoError?.shopAddress.error ? classes.TextFieldRoot : classes.noStyle,
                        }}
                        label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("type_street_address")}</Typography>}
                        variant="outlined"
                        onChange={handleChangeStreetAddress} />
                    {delarDtoError?.shopAddress.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.shopAddress?.msg}
                    </Typography>}
                </form>
            </React.Fragment>


            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("landmark")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField id="outlined-basic"
                        value={delarDto?.shopAddress.landMark}
                        className={classes.textField1}
                        label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">{t("type_nearby_landmark")}</Typography>}
                        variant="outlined"
                        onChange={handleChangeLandMark} />

                </form>
            </React.Fragment>

            <Typography variant="body1" className={classes.title4} >
                <b>{t("state")}</b>
            </Typography>
            <StatePickerComponent
                setState={setState} />
            {delarDtoError?.state.error && <Typography variant="body1" className={classes.errorText}>
                <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.state?.msg}
            </Typography>}

            {delarDto?.shopAddress?.geoAddress?.state?.name && delarDto?.shopAddress?.geoAddress?.state?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title2} >
                        <b>{t("district")}</b>
                    </Typography>

                    <DistrictPickerComponent
                        setDistrict={setDistrict}
                        stateCode={delarDto.shopAddress.geoAddress.state.code} />
                    {delarDtoError?.district.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.district?.msg}
                    </Typography>}
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.district?.name && delarDto?.shopAddress?.geoAddress?.district?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title2} >
                        <b>{t("city")}</b>
                    </Typography>

                    < SubDistrictPickerComponent
                        setSubDistrict={setSubDistrict}
                        districtCode={delarDto.shopAddress.geoAddress.district.code} />
                    {delarDtoError?.city.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.city?.msg}
                    </Typography>}
                </React.Fragment>
            )}

            {delarDto?.shopAddress?.geoAddress?.subDistrict?.name && delarDto?.shopAddress?.geoAddress?.subDistrict?.name.length > 0 && (
                <React.Fragment>
                    <Typography variant="body1" className={classes.title2} >
                        <b>{t("village")}</b>
                    </Typography>
                    <VillagePickerComponent
                        setVillage={setVillage}
                        subDistrictCode={delarDto.shopAddress.geoAddress.subDistrict.code} />
                    {delarDtoError?.village.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.village?.msg}
                    </Typography>}
                </React.Fragment>
            )}

            <React.Fragment>
                <Typography variant="body1" className={classes.title2} >
                    <b>{t("pincode")}</b>
                </Typography>
                <form className={classes.formField} noValidate autoComplete="off">
                    <TextField
                        id="outlined-basic"
                        value={delarDto?.shopAddress.pinCode}
                        type="number"
                        className={classes.textField1}
                        label={<Typography variant="body2" style={{ fontSize: 12 }} color="textSecondary">123456</Typography>}
                        variant="outlined"
                        onChange={handleChangePinCode} />
                    {delarDtoError?.pinCodeError?.error && <Typography variant="body1" className={classes.errorText}>
                        <ErrorOutlineIcon className={classes.errorIcon} />{delarDtoError?.pinCodeError?.msg}
                    </Typography>}
                </form>
            </React.Fragment>

        </div>
    )
}
