import { AppBar, Avatar, BottomNavigation, Button, Card, Checkbox, Chip, Divider, InputBase, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useStyles } from './deliverable-village-add.style'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { Village } from "../../../../service/response/village.response"
import { useNavigate } from "react-router"
import CloseIcon from '@material-ui/icons/Close';
import LoadingComponent from "../../../common/loading/loading.component"
import { useTranslation } from "react-i18next"
import { SecurityManager } from "../../../../security/security.manager"
import { DelarService } from "../../../../service/delar.service"
import { AddressService } from "../../../../service/address.service"
import { VillageSearchDto } from '../../../../service/dto/village-search.dto'
import { useRouteManager } from "../../../../router/route.manager"
import { AddVillageDto } from "../../../../service/dto/add-village.dto"
export const AddVillageComponent = () => {
    const navigate = useNavigate()
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [villageData, setVillageData] = useState<Array<Village>>([])
    const [selectedVillageData, setSelectedVillageData] = useState<Array<Village>>([])
    const [isSearchTextChanged, setIsSearchTextChanged] = useState(false);
    const timeOut = useRef<any>(0)
    const routeManager = useRouteManager();
    
    useEffect(()=>{
        setLoading(true)
        DelarService.getDelarById(SecurityManager.getUid()).then((res) => {
            AddressService.getVillageBySubDistrictCode(res.data.shopAddress.geoAddress.subDistrict.code).then((result) => {
                setVillageData([...result.data])
                setLoading(false)
            })
        })
    }, [])
   
    const handleSearch = (e: any) => {
        let searchDto = {limit: 10} as VillageSearchDto
        searchDto.name = e.target.value
        AddressService.getVillageBySearch(searchDto).then((res) => {
            if(res.data)
            setVillageData([...res.data])
            else
            setVillageData([])
        })
    }
    const checkSelect = (villageId: any): boolean => {
        let flag = false;
        selectedVillageData.map((village)=> {
            if(village.id === villageId)
            {
                flag = true;
                return;
            }
        })
        return flag;
    }
    const handleToggle = (event: any, village: Village) => {
        if(event.target.checked)
        {
            selectedVillageData.push(village)
            setSelectedVillageData([...selectedVillageData])
        }
        else
        {
            let idx = -1;
            selectedVillageData.map((selectVillage: Village, index: number) => {
                if(village.id === selectVillage.id)
                {
                    idx = index
                    return;
                }
            })
            selectedVillageData.splice(idx, 1)
            setSelectedVillageData([...selectedVillageData])
        }
    }
    const handleDelete = (village: Village) => {
        let idx = -1;
            selectedVillageData.map((selectVillage: Village, index: number) => {
                if(village.id === selectVillage.id)
                {
                    idx = index
                    return;
                }
            })
            selectedVillageData.splice(idx, 1)
            setSelectedVillageData([...selectedVillageData])
    }
    const saveVillages = () => {
        if(selectedVillageData.length > 20)
        {
            alert("A Maximum of 20 villages can be selected!")
            return;
        }
        const addDtoList: AddVillageDto[] = []

        selectedVillageData.map((village) => {
            const addDto = {} as AddVillageDto
            addDto.delarId = SecurityManager.getUid()
            addDto.villageId = village.id
            addDtoList.push(addDto)
        })

        AddressService.addVillageDelivery(addDtoList).then((res: any)=>{
        })
        routeManager.openHome()
    }
    return (
        <div className = {classes.root}>
            <AppBar position="sticky">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick = {() => {navigate(-1)}}/>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            
            <Card className={classes.topButtonCard} variant="outlined">
                {selectedVillageData && selectedVillageData.length > 0 && (
                    <div className = {classes.chipHolder}>
                        {selectedVillageData.map((selectedVillage, index) => {
                            return (
                                <Chip
                                    key={selectedVillage.id}
                                    label={selectedVillage.name}
                                    onDelete={() =>{handleDelete(selectedVillage)}}
                                    className = {classes.chip}
                                    deleteIcon = {<CloseIcon style = {{color: '#000',}}/>}
                                />
                            );
                        })}
                    </div>
                )}
            </Card>
            
            {!loading &&
            <List dense style={{ width: '100%', backgroundColor: 'background.paper', marginBottom: 40, }}>
                {villageData.map((village, index) => {
                    const labelId = `${village.id}`
                    let found = checkSelect(village.id)
                    return (
                        <>
                        <ListItem key={index} button>
                                <ListItemIcon>
                                { (
                                    <Checkbox
                                        edge="end"
                                        onChange={(e: any) => { handleToggle(e, village) }}
                                        checked={found}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        color="primary"
                                    />
                                )}
                                </ListItemIcon>
                            <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.villageName}>
                                {village.name}
                            </Typography>}
                                 />
                            
                        </ListItem>
                        <Divider style = {{marginBottom: 12.5,}}/>
                        </>
                    );
                })}
            </List>
            }
            {
                loading && <LoadingComponent />
            }
            <BottomNavigation showLabels className={classes.bottomNavigation}>
                <Button  className={classes.skipButton} onClick = {() => {routeManager.openHome()}}variant = "outlined">{t("skip")}</Button>
                <Button className={classes.nextButton} variant="contained" color="primary" onClick = {saveVillages}>
                    {t("save")}
                </Button>
            </BottomNavigation>
        </div>
    )
}