export const EditProfileAction = {
    UPDATE_NAME: "update_name",
    UPDATE_PHONE: "update_phone",
    UPDATE_INTRO: "update_intro",
    UPDATE_SHOP_NAME: "update_shop_name",
    UPDATE_PESTICIDE_LICENSE: "update_pesticide_license",
    UPDATE_FERTILIZER_LICENSE: "update_fertilizer_license",
    UPDATE_SEED_LICENSE: "update_seed_license",
    UPDATE_STREET_ADDRESS: "update_street_address",
    UPDATE_LANDMARK: "update_landmark",
    UPDATE_STATE: "update_state",
    UPDATE_DISTRICT: "update_district",
    UPDATE_SUB_DISTRICT: "update_sub_district",
    UPDATE_VILLAGE: "update_village",
    UPDATE_PINCODE: "update_pincode",
    UPDATE_DATA: "update_data",
}