import { useNavigate } from "react-router-dom"

export const useRouteManager = () => {
    const navigate = useNavigate()

    const clearHistory = () => {

    }

    const openCamera = () => {
        navigate('/camera')
    }
    const openAddVillage = () => {
        navigate('/on-board/add-village')
    }
    const openRegister = () => {
        navigate(`/on-board/register`)
    }

    const openOTPValidation = () => {
        navigate(`/on-board/validate-otp`)
    }

    const openIntro = () => {
        navigate(`/on-board/intro`)
    }

    const openDelarDetail = () => {
        navigate(`/on-board/detail`)
    }

    const openDelarEditDetailPage = () => {
        navigate(`/on-board/edit-detail`)
    }

    const openHome = () => {
        navigate(`/home`)
    }

    const openProduct = () => {
        navigate(`/product`)
    }

    const openProductDetail = (productId: string) => {
        navigate(`/product/${productId}`)
    }

    const openAddProduct = () => {
        navigate(`/product/add`)
    }

    const openNewProductReq = () => {
        navigate(`/product/new-request`)
    }

    const openProfile = () => {
        navigate(`/profile`)
    }

    const openNotification = () => {
        navigate(`/notification`)
    }

    const openSetting = () => {
        navigate(`/settings`)
    }

    const openAboutUs = () => {
        navigate(`/about-us`)
    }

    const openTermsPolicies = () => {
        navigate(`/terms-policies`)
    }


    return {
        clearHistory: clearHistory,
        openRegister: openRegister,
        openOTPValidation: openOTPValidation,
        openIntro: openIntro,
        openDelarDetail: openDelarDetail,
        openDelarEditDetailPage: openDelarEditDetailPage,
        openHome: openHome,
        openProduct: openProduct,
        openProductDetail: openProductDetail,
        openAddProduct: openAddProduct,
        openNewProductReq: openNewProductReq,
        openProfile: openProfile,
        openNotification: openNotification,
        openSetting: openSetting,
        openAboutUs: openAboutUs,
        openTermsPolicies: openTermsPolicies,
        openCamera: openCamera,
        openAddVillage: openAddVillage,
    }
}