import { Avatar, Button, Card, CardContent, Container, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { DelarAction } from "../../../action/delar.action";
import { DelarActor } from "../../../actor/delar.actor";
import { Media } from "../../../model/media.model";
import { SecurityManager } from "../../../security/security.manager";
import { DelarStore } from "../../../store/delar.store";
import { useObservable } from "../../../useSubscriber";
import { PendingComponent } from "../profile/application-cards/pending/pending.component";
import { RejectedComponent } from "../profile/application-cards/rejected/rejected.component";
import { useStyles } from "./home.style"
import { useTranslation } from "react-i18next"
import { DelarStatus } from "../../../enum/delar-status.enum";

export const HomeComponent = () => {

    const classes = useStyles();
    const [delarInfo, _] = useObservable(DelarStore.getDelarInfo(), DelarStore.getDelarInfoObservable())
    const { t } = useTranslation();

    useEffect(() => {
        DelarActor({ "type": DelarAction.GET_DELAR_INFO }, { "delarID": SecurityManager.getUid() })
    }, [])

    return (
        <div className={classes.root}>
            {delarInfo?.shopPhotos && (
                <React.Fragment>
                    <div style={{ flex: 1, alignItems: 'stretch', position: 'relative' }}>
                        <img src={new Map(Object.entries((new Map(Object.entries(delarInfo.shopPhotos)).values().next().value as Media).urls)).get('main')} alt={'shopImage'} className={classes.img} />
                        <Typography variant="h6" className={classes.title1}>
                            {delarInfo.shopName.newName}
                        </Typography>
                    </div>
                </React.Fragment>
            )}
            {delarInfo?.status?.value == DelarStatus.Rejected && (
                <React.Fragment>
                    <RejectedComponent />
                </React.Fragment>
            )}
            {delarInfo?.status?.value == DelarStatus.Pending && (
                <React.Fragment>
                    <PendingComponent />
                </React.Fragment>
            )}
            {delarInfo?.status?.value == DelarStatus.Verified && (
                <React.Fragment>
                    <Card className={classes.cardEdit1}>
                        <CardContent>
                            <Typography variant="body1" className={classes.title2}>
                                {t("home_advice_1")}
                            </Typography>
                            <Typography paragraph variant="subtitle1" className={classes.title3} >
                                {t("home_advice_2")}
                            </Typography>
                            <div className={classes.shareDiv}>
                                <Avatar alt="editIcon" src={'../asset/userIcon.svg'} className={classes.icon} />
                                <Typography variant="subtitle1" display="inline" className={classes.title4} style={{ color: 'blue', }} noWrap>
                                    {t("home_link")}
                                </Typography>
                                
                                <Button variant="contained" color="primary" className={classes.shareButton} >
                                    <img style={{ width: 27, height: 27 }} src='../asset/Whatsapp.svg' />
                                    <Typography style={{ opacity: 0.8, fontSize: 12 }}>
                                        {t("share")}
                                    </Typography>
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </React.Fragment>
            )}
            {false && (
                <React.Fragment>
                    <Card className={classes.cardEdit2}>
                        <CardContent>
                            <Typography variant="body1" className={classes.title5}>
                                <b> {t("shop_overview")} </b>
                            </Typography>
                            <div style={{ display: "flex" }}>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("total_shop_view")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 8 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("today_shop_view")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 0 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("total_call")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 9 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("product_view")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 23 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </CardContent>
                    </Card>
                </React.Fragment>
            )}
            {delarInfo?.status?.value == DelarStatus.Verified && (
                <React.Fragment>
                    <Card className={classes.cardEdit2}>
                        <CardContent>
                            <Typography variant="body1" className={classes.title5}>
                                <b> {t("product_overview")} </b>
                            </Typography>
                            <div style={{ display: "flex" }}>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("total_product")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 1 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("enable_product")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 4 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Card className={classes.cardEdit3}>
                                    <CardContent>
                                        <Typography variant="subtitle1" className={classes.title5_1}>
                                            {t("disable_product")}
                                        </Typography>
                                        <Typography variant="h6" className={classes.title6}>
                                            <b> 6 </b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </CardContent>
                    </Card>
                </React.Fragment>
            )}
        </div>
    )
}