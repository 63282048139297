import {TextField, Typography} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { AddressAction } from '../../../action/address.action';
import { AddressActor } from '../../../actor/address.actor';
import { Village } from '../../../service/response/village.response';
import { AddressStore } from '../../../store/address.store';
import { useObservable } from '../../../useSubscriber';
import { DelarStore } from "../../../store/delar.store"
import {useStyles} from './ui.style';
import { useTranslation } from "react-i18next"
export const VillagePickerComponent = (props: any) => {

    const [villageList, villageListError] = useObservable(AddressStore.getVillageList(), AddressStore.getVillageListObservable())
    const [delarDtoError, dtoee] = useObservable(DelarStore.getDelarDtoError(), DelarStore.getDelarDtoErrorObservable())
    const { t } = useTranslation();
    const [villageData, setVillageData] = useState<Array<Village>>([])
    const classes = useStyles();
    useEffect(() => {
        AddressActor({ "type": AddressAction.GET_VILLAGE_LIST }, {"subDistrictCode": props.subDistrictCode})
    }, [])

    useEffect(() => {
        if (!villageListError.error && villageList) {
            var newVillages: Array<Village> = Object.keys(villageList).map(function(index: any){
                let village = (villageList as Village[])[index]
                return village;
            });
            setVillageData(newVillages)
        }
    }, [villageList])

    return (
        <Autocomplete
            id="combo-box-demo"
            options={villageData}
            getOptionLabel={(option: Village) => option.name}
            onChange={(event, village) => props.setVillage(village)}
            fullWidth={true}
            loading={villageData.length > 0 ? true : false}
            classes={{ input: classes.input  }}
            style={{marginTop:8,}}
            renderInput={(params: any) => <TextField {...params}
            classes = {{
                root: delarDtoError?.village.error?classes.TextFieldRoot: classes.noStyle,
            }}
                label={<Typography variant="body2" style ={{fontSize: 12}} color="textSecondary">{t("choose_village")}</Typography>} variant="outlined" />}
        />
    );
}