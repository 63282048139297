import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: "50%",
        },
        img: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            height: 200,
            maxWidth: "100%",
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        title1: {    
            color: '#fff',
            position:'absolute',
            bottom:8, 
            left:15,
        },
        cardEdit1: {
            align: 'stretch',
            
            marginLeft:8,
            marginRight:8,
            marginTop: 12,
            fontSize: 14,
        },
        title2: {
            marginTop: 8,
            marginLeft: 8,
            
            color: "#2A312F",
            
            font: "normal normal medium 14px/18px Montserrat",
        },
        title3: {
            
            marginLeft: 8,
            marginTop: 6,
            maxLines: 4,
            fontSize: 12,
            color: '#2A312F',
            letterSpacing: 0,
        },
        shareDiv: {
            display:"flex", 
            
        },
        icon: {
            marginLeft: 19,
            marginTop: 17,
            
            width: 15,
            height: 20,
            verticalAlign: 'middle',
        },
        title4: {
            maxWidth: 129,
            marginTop: 15,
            marginLeft: 6,
            marginRight: 'auto',
            fontSize: 12,
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        shareButton: {
            width:154, 
            height: 44,
            marginLeft:32, 
            marginRight: 'auto',
            borderRadius: 30,
            textTransform: 'capitalize',
            fontSize:12,
        },
        cardEdit2: {
            align: 'stretch',
            marginLeft:8,
            marginRight:8,
            marginTop: 12,
        },
        title5: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 'auto',
            fontSize: 14,
            fontWeight: 'bold',
        },
        title5_1: {
            fontSize: 12,
            fontWeight: 'bold',
            opacity: 0.8,
            
        },
        cardEdit3: {
            width: "45%",
            marginLeft:8,
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
        },
        title6: {
            flexGrow: 2,
            fontSize:24,
            opacity: 0.8,
        },
        cardEdit4: {
            width: "45%",
            marginLeft:8,
            marginTop: 5,
        },
    }),
);