import { AppBar, Avatar, Divider, MobileStepper, Toolbar, Typography, useTheme } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DeleteIcon from '@material-ui/icons/Delete'
import SwipeableViews from 'react-swipeable-views'
import { useStyles } from './product-info.style'
import { useObservable } from "../../../../useSubscriber"
import { ProductStore } from "../../../../store/product/product.store"
import { ProductComponent } from "../product.component"
import { idSelected } from "../product.component" 
import { ProductActor } from "../../../../actor/product/product.store"
import { ProductAction } from "../../../../action/product/product.store"
import { Media } from "../../../../model/media.model"


const productImages = [
    {
        label: 'image1',
        imgPath: '',
    },
    {
        label: 'image2',
        imgPath: '',
    },
    {
        label: 'image3',
        imgPath: '',
    },
    {
        label: 'image4',
        imgPath: '',
    },
];

export const ProductInfoComponent = () => {

    const classes = useStyles();
    const theme = useTheme();
    const maxSteps = productImages.length;
    const [activeStep, setActiveStep] = React.useState(0);
    const [productInfo, _] = useObservable(ProductStore.getDelarProductInfo(), ProductStore.getDelarProductInfoObservable())
    
    useEffect(() => {
        if(idSelected == undefined || idSelected == null)
        {
            var idOnRefresh = localStorage.getItem("id")
            ProductActor({"type": ProductAction.OPEN_PRODUCT_INFO},{"delarProductID": idOnRefresh})
        }
        else
        {
            localStorage.setItem("id",idSelected)
            ProductActor({"type": ProductAction.OPEN_PRODUCT_INFO},{"delarProductID": idSelected})
        }
    }, [])

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <React.Fragment>
            {productInfo?.product?.name && productInfo?.product?.name.length >0 && (
            <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} />
                    <Typography variant="h6" className={classes.title}>
                        {productInfo.product.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            </React.Fragment>
            )}
            {productInfo?.product?.name && productInfo?.product?.name.length >0 && (
                <React.Fragment>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {productImages.map((step, index) => (
                        <div key={step.label} style = {{width: '100%', position: 'relative'}}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                
                            <img className={classes.img} src={new Map(Object.entries((new Map(Object.entries(productInfo.product.photos)).values().next().value as Media).urls)).get('main') as string} alt={step.label} />
                            ) : null}
                            <div className = {classes.iconHolder}>
                                <DeleteIcon  style = {{width: 20, height: 20, opacity: 0.5, }}/>
                            </div>
                            <div className = {classes.iconHolder} style = {{top: 64}}>
                                <i className="fas fa-share "  style = {{ opacity: 0.5, }}></i>
                            </div>
                        </div>
                    ))}
                </SwipeableViews>
                </React.Fragment>
            )}
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={null}
                backButton={null}
                style={{alignItems: 'center', justifyContent: 'center',}} />
              
            <div style={{display:"flex", width:"100%",}}>
                <Avatar alt={'graphIcon'} src={'../asset/graph.svg'} className={classes.graphIcon}/>
                <Typography variant = "subtitle1" className = {classes.title1}>
                    <b> 10000 Product Views </b>
                </Typography>                      
            </div>
            <Divider style = {{marginTop: 18.5, marginBottom: 15.5,}}/>
            {productInfo?.product?.name && productInfo?.product?.name.length >0 && (
                <React.Fragment>
                    <Typography variant="h6" className={classes.title2}>
                        <b> {productInfo.product.name} </b>
                    </Typography>
                </React.Fragment>
            )}
            {productInfo?.product?.company?.name && productInfo?.product?.company?.name.length >0 && (
                <React.Fragment>    
                    <Typography variant="subtitle1" className={classes.title3}>
                        {productInfo.product.company.name}
                    </Typography>
                </React.Fragment>
            )}
            <Typography variant="h6" className={classes.title2}>
                <b> Chemical </b>
            </Typography>
            {/*TODO*/}
            <Typography variant="subtitle1" className={classes.title3}>
                Thiamenthoxam 12.6% + Lambdacyhalothrin 
            </Typography>
            <Typography variant="h6" className={classes.title2}>
                <b> About </b>
            </Typography>
            {productInfo?.product?.description && productInfo?.product?.description.length >0 && (
                <React.Fragment>
                    <Typography paragraph variant="subtitle1" className={classes.title4}>
                        {productInfo.product.description}
                    </Typography>
                </React.Fragment>
            )}
            <Typography variant="h6" className={classes.title2}>
                <b> Dosage </b>
            </Typography>
            {/*TODO*/}
            <Typography variant="subtitle1" className={classes.title3}>
                50 Gm/Acer
            </Typography>

        </React.Fragment>
    )
}