import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            
           // backgroundColor: '#aaa',
        },
        titleEdit: {
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(4),
            fontSize: 24,
            fontWeight: 'bold',
        },
        descEdit: {
            marginLeft: theme.spacing(2),
            marginTop: 8,
            fontSize: 12,
        },
        container: {
            width: '100%',
            margin: 'auto',
            marginTop: '60%',
            height: 320,
           // backgroundColor: '#ccc',
        },
        img: {
            
            height: '50%',
            margin: 'auto',
            overflow: 'hidden',
            display: 'block',
            width: '100%',
        },
        positionStatic: {
            position: 'fixed',
            marginLeft: '36%',
            bottom: 89,
        },
        nextButton: {
            position: 'fixed',
            bottom: 20,
            right: 17,
            align: 'center',
            width: 166,
            height: 48,
            fontSize: 14,
            textTransform: 'capitalize',
            backgroundColor: '#0E4BB4',
        },
        backButton: {
            position: 'fixed',
            bottom: 20,
            left: 0,
            align: 'center',
            width: 166,
            height: 48,
            fontSize: 14,
            textTransform: 'capitalize',
            color: '#0E4BB4',
        },
    }),
);