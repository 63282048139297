import { BehaviorSubject, Observable } from "rxjs"
import { DelarProductSearchDto, ProductSearchDto } from "../../service/dto/product.dto"
import { DelarProduct, Product } from "../../service/response/product.response"


let _delarProductList: Array<DelarProduct> = []
let _delarProductListSubject = new BehaviorSubject<Array<DelarProduct> | null>(null)

let _delarProductSearchDto: DelarProductSearchDto = {delarID:"60b77b7158d11cabb2350bbf",limit:10} as DelarProductSearchDto
let _delarProductSearchDtoSubject = new BehaviorSubject<DelarProductSearchDto | null>(null)

let _delarProductInfo: DelarProduct = {product:{name:"",company:{name:""},chemical:{chemicalID:""},description:""}} as DelarProduct
let _delarProductInfoSubject = new BehaviorSubject< DelarProduct | null>(null)

export const ProductStore = {
    upadteDelarProductList: (newDelarProductList: Array<DelarProduct>) => {
        _delarProductList = newDelarProductList
        _delarProductListSubject.next(_delarProductList)
    },
    getDelarProductList: (): Array<DelarProduct> | null => {
        return _delarProductList
    },
    getDelarProductListObservable: (): Observable<Array<DelarProduct> | null> => {
        return _delarProductListSubject.asObservable()
    },
    upadteDelarProductSearchDto: (newDelarProductSearchDto: DelarProductSearchDto) => {
        _delarProductSearchDto = newDelarProductSearchDto
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    updateSearchText: (searchText: string) => {
        _delarProductSearchDto = {delarID:"60b77b7158d11cabb2350bbf",limit:10} as DelarProductSearchDto
        _delarProductSearchDto.searchText = searchText
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    updateCompanyId: (companyIds: Array<string>) => {
        _delarProductSearchDto.companyIds = companyIds
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    updateAvailability: (available: number) => {
        _delarProductSearchDto.avability = available
        _delarProductSearchDtoSubject.next({ ..._delarProductSearchDto })
    },
    getDelarProductSearchDto: (): DelarProductSearchDto => {
        return _delarProductSearchDto
    },
    getDelarProductSearchDtoObservable: (): Observable<DelarProductSearchDto | null> => {
        return _delarProductSearchDtoSubject.asObservable()
    },
    upadteDelarProductInfo: (newDelarProductInfo: DelarProduct) => {
        _delarProductInfo = newDelarProductInfo
        _delarProductInfoSubject.next(_delarProductInfo)
        //console.log(_delarProductInfo)
    },
    getDelarProductInfo: (): DelarProduct | null => {
        return _delarProductInfo
    },
    getDelarProductInfoObservable: (): Observable< DelarProduct | null> => {
        return _delarProductInfoSubject.asObservable()
    },
}