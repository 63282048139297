import { AppBar, Avatar, BottomNavigation, Modal, Button, Card, CardContent, Checkbox, Container, InputBase, List, ListItem, ListItemSecondaryAction, ListItemText, Toolbar, Typography } from "@material-ui/core"
import React, {useState, useEffect} from "react"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useStyles } from "./filter.style"
import { FilterService } from "../../../../service/filter.service"
import { useObservable } from "../../../../useSubscriber";
import SearchIcon from '@material-ui/icons/Search'
import { FilterActor } from "../../../../actor/filter.actor";
import { FilterStore } from "../../../../store/filter.store";
import { FilterAction } from "../../../../action/filter.action";
import { Company } from "../../../../service/response/company.response";
import { Category } from "../../../../service/response/category.response";
import { useTranslation } from "react-i18next"





export const FilterComponent = (props: any) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [filterBy, setFilterBy] = useState(0);
    const [companyList, companyListError] = useObservable(FilterStore.getCompanyList(), FilterStore.getCompanyListObservable());
    const [cropCategoryList, cropCategoryListError] = useObservable(FilterStore.getCropCategoryList(), FilterStore.getCropCategoryListObservable());
    const [checkedList, setCheckedList] = useState([[], [], []]);
    const handleCheck = (e: any) => {
        console.log(e);
        
        if(e.target.checked)
        {
            checkedList[filterBy].push(e.target.id);
        }
        else
        {
            let index = -1;
            for( var i = 0; i < checkedList[filterBy].length; i ++)
            {
                if(checkedList[filterBy][i] === e.target.value.id)
                {
                    index = i;
                    break;
                }
            }
            checkedList[filterBy].splice(index, 1);
        }
        setCheckedList([...checkedList]);
    };
    const handleApplyFilter = () => {
        console.log(checkedList);
        props.handleFilter(checkedList);
        props.handleClose();
    }
    const [listContentsRight, setListContentsRight] = useState([
        [
            {
                id: "0",
                name: t("available"),
            },
            {
                id: "1",
                name: t("unavailable"),
            }
        ],
        [
    
        ],
        [
        
        ],
    ]);
    const listContentsLeft = [
        {
            id:0,
            name: t("availability"),
        },
        {
            id:1,
            name: t("category"),
        },
        {
            id:2,
            name: t("company"),
        },
    ];
    useEffect(() => {
        FilterActor({ "type": FilterAction.GET_COMPANY });
        FilterActor({ "type": FilterAction.GET_CROP_CATEGORY});
    }, []);
    useEffect(() => {
        if (companyList) {
            var newCompanyLists: Array<Company> = Object.keys(companyList).map(function (index: any) {
                let company = (companyList as Company[])[index]
                return company;
            });
            setListContentsRight([listContentsRight[0], listContentsRight[1], newCompanyLists as any ])
        }
    }, [companyList]);
    
    useEffect(() => {
        if (cropCategoryList) {
            var newCropCategoryLists: Array<Category> = Object.keys(cropCategoryList).map(function (index: any) {
                let category = (cropCategoryList as Category[])[index]
                return category;
            });
            setListContentsRight([listContentsRight[0], newCropCategoryLists as any, listContentsRight[2]] )
        }
    }, [cropCategoryList]);
    return (
        <Modal
            open = {props.open}
            onClose = {props.handleClose}
             style = {{ opacity: 1,}}
        >
        {<div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <ArrowBackIcon className={classes.backIcon} onClick = {props.handleClose}/>
                    <Typography variant="h6" className={classes.title}>
                        {t("filter_by")}
                    </Typography>
                    <Typography variant="body1" className={classes.clearFilter}>
                        {t("clear_filter")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.container} style={{display:"flex"}} >
                <div className={classes.leftPane} >
                    <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
                        {listContentsLeft.map((step, index) => {
                            const labelId = `${step.id}`
                            return (
                                <ListItem key={index} button onClick = {() => {setFilterBy(step.id)}}>
                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" 
                                            className={classes.companyName}
                                            style = {{color: `${step.id === filterBy? '#1078ff': '#000'}`}}
                                            >
                                        <b>{step.name}</b>
                                    </Typography>}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>                    
                </div>
                <div className={classes.rightPane}>
                   { filterBy !== 0 && <div className={classes.search} style = {{border: '0.5px solid #7E8184', margin: 'auto', marginTop: 13, width: '90%',}}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            //onChange={(e: any) => { handleSearch(e) }}
                        />
                    </div>}
                    <List dense style={{ width: '100%', backgroundColor: 'background.paper' }}>
                        {listContentsRight[filterBy].map((step, index) => {
                            const labelId = `${step.id}`
                            return (
                                <ListItem key={index} button>
                                    <Checkbox
                                            id = {step.id}
                                            edge="end"
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            color="primary"
                                            style = {{marginRight: 8,}}
                                            checked = {function(){
                                                for(var i = 0; i < checkedList[filterBy].length; i ++)
                                                {
                                                    if(checkedList[filterBy][i] === step.id)
                                                    return true;
                                                }
                                                return false;
                                            }()}
                                            onChange = {(e) => {handleCheck(e) }}
                                        />
                                    <ListItemText disableTypography id={labelId} primary={<Typography variant="body2" className={classes.companyName}>
                                        {step.name}
                                    </Typography>}
                                    />
                                    
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>
            <BottomNavigation
                //showLabels
                className={classes.bottomNavigation}
            >
                <Typography variant="subtitle1" style={{alignSelf: 'left',justifySelf: 'left',maxLines:2, }}>
                        <b style = {{fontSize: 14, marginBottom: 0,}}>123</b> <br/> <p style = {{opacity: 0.5,margin: 0, fontSize: 12,}}>Products found</p>
                </Typography>
                <Button color="primary" variant="contained" className = {classes.button} onClick = {handleApplyFilter}>
                    {t("apply")}
                </Button>         
            </BottomNavigation>
        </div>}
        </Modal>
    );
}