import { BehaviorSubject, Observable } from "rxjs"
import { ProductSearchDto } from "../../service/dto/product.dto"
import { DelarProductSearchResult } from "../../service/response/product.response"

let _productList: Array<DelarProductSearchResult> = []
let _productListSubject = new BehaviorSubject<Array<DelarProductSearchResult> | null>(null)

let _productSearchDto: ProductSearchDto = {limit:10} as ProductSearchDto
let _productSearchDtoSubject = new BehaviorSubject<ProductSearchDto | null>(null)

let _selectedProductsList: Array<DelarProductSearchResult> = []
let _selectedProductsListSubject = new BehaviorSubject<Array<DelarProductSearchResult> | null>(null)

export const AddProductStore = {
    upadteProductList: (newProductList: Array<DelarProductSearchResult>) => {
        _productList = newProductList
        _productListSubject.next(_productList)
    },
    getProductList: (): Array<DelarProductSearchResult> | null => {
        return _productList
    },
    getProductListObservable: (): Observable<Array<DelarProductSearchResult> | null> => {
        return _productListSubject.asObservable()
    },
    addDelarProductDto: (delarProductSearchResult: DelarProductSearchResult) => {
        if (_selectedProductsList.indexOf(delarProductSearchResult) != -1) {
            return
        }
        _selectedProductsList.push(delarProductSearchResult)

        _productList = _productList.map(product => {
            if(product.id == delarProductSearchResult.id) {
                product.checked = true
            }
            return product
        })
        _productListSubject.next({..._productList})
        _selectedProductsListSubject.next({ ..._selectedProductsList })
    },
    deleteDelarProductDto: (delarProductSearchResult: DelarProductSearchResult) => {
        _selectedProductsList = _selectedProductsList.filter(selectedProduct => {
            if (selectedProduct.id == delarProductSearchResult.id) {
                return false
            }
            return true
        })
        _productList = _productList.map(product => {
            if(product.id == delarProductSearchResult.id) {
                product.checked = false
            }
            return product
        })
        _productListSubject.next({..._productList})
        _selectedProductsListSubject.next({ ..._selectedProductsList })
    },
    getDelarProductDtoList: (): Array<DelarProductSearchResult> | null => {
        return _selectedProductsList
    },
    getDelarProductDtoListObservable: (): Observable<Array<DelarProductSearchResult> | null> => {
        return _selectedProductsListSubject.asObservable()
    },
    upadteProductSearchDto: (newProductSearchDto: ProductSearchDto) => {
        _productSearchDto = newProductSearchDto
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },
    updateSearchText: (searchText: string) => {
        _productSearchDto = {limit:10} as ProductSearchDto
        _productSearchDto.searchText = searchText
        _productSearchDtoSubject.next({ ..._productSearchDto })
    },
    getProductSearchDto: (): ProductSearchDto => {
        return _productSearchDto
    },
    getProductSearchDtoObservable: (): Observable<ProductSearchDto | null> => {
        return _productSearchDtoSubject.asObservable()
    },
}