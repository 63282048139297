import { FilterAction } from "../action/filter.action";
import { FilterService } from "../service/filter.service";
import { FilterStore } from "../store/filter.store";
import { ApiError } from "../model/error.model";

const getCompanyList = () => {
    FilterService.getCompany().then(res => {
        FilterStore.setCompanyList(res.data)
    }).catch(err => {
        FilterStore.setCompanyList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}
const getCropCategoryList = () => {
    FilterService.getCropCategory().then(res => {
        FilterStore.setCropCategoryList(res.data)
    }).catch(err => {
        FilterStore.setCropCategoryList({
            "msg": "err",
            "error": true
        } as ApiError)
    })
}
export const FilterActor = (action: any) => {
    switch(action.type){
        case FilterAction.GET_COMPANY:
            getCompanyList()
            break;
        case FilterAction.GET_CROP_CATEGORY: 
            getCropCategoryList()
            break;
        default:
            break; 
    }
}