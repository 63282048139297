import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: '#fff',
        },
        backIcon: {
            color: '#fff', 
            fontSize: 40,           
        },
        title: {
            flexGrow: 1,
            align: "left",
            marginLeft: theme.spacing(1),
            fontSize: 14,
        },
        clearFilter: {
            position: 'fixed',
            right: 16.5,
            
            fontSize: 14,
        },
        container: {
            
            width: '100%',
            height: '90vh',
            
        },
        leftPane: {
            width: '40%',
            //minWidth: 350,
            height: 'available',
            //float: 'left',
            backgroundColor: '#f7f7f7',
            borderCollapse: 'collapse',
        },
        rightPane: {
            width: '60%',
            height: 'available',
            position: 'relative',
            //backgroundColor: 'yellow',
            borderCollapse: 'collapse',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
              backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              marginLeft: theme.spacing(3),
              width: 'auto',
            },
          },
          searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.5,
          },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '20ch',
            },
        },
        companyName: {
            fontSize: 12,
           
        },
        bottomNavigation: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 16,
            
        },
        button: {
            height:47, 
            width: 132,
            textTransform: 'capitalize',
            marginRight: 30,
        },
    })
);
