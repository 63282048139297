import { Session } from "./model/session.model"

const Android = (window as any).Android

export const AndroidConnerctor = {
    getSession: (): string => {
        if (Android) {
            console.log(`========================>12${JSON.stringify(Android.getSession())}`)
            console.log("========================>1Android")
            return Android.getSession()
        }
        return "{}"
    },
    getLanguage: (): string => {
        if (Android) {
            return Android.getLanguage()
        }
        return ""
    },
    setSession: (session: Session) => {
        if (Android) {
            Android.setSesion(session)
        }
        console.log("========================>2Android")
    },
    setLanguage: (lang: string) => {
        if (Android) {
            Android.setLanguage(lang)
        }
        console.log("========================>3Android")
    },
    updateStatus: (status: Number) => {
        if (Android) {
            Android.updateStatus(status)
        }
        console.log("========================>2Android")
    },
    removeSession: () => {
        if (Android) {
            Android.logOut()
        }
    }
}